.Pagination
{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    .prev{
        cursor: pointer;
        
        padding: 4px;
        font-size: 1rem;
        font-weight: bold;
        opacity: 0.7;
        margin-right: 5px;
    }
    .prev:hover , .last:hover {
        opacity: 0.9;
    }
    .item{
        
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white ;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        opacity: 0.7;
        font-size: 1rem;
        font-weight: bold;
        color: #424242;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        transition: all 0.5s;
        cursor: pointer;
    }
    .item.active{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-color: var(--adminMainColor);
        color: white;
    }
    .item:hover{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        background-color: var(--adminMainColor);
        color: white;
    }
    .last{
        padding: 10px;
        font-size: 1.2rem;
        font-weight: bold;
        opacity: 0.7;
        margin-left: 10px;
        cursor: pointer;
    }
}