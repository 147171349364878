.detail-content {
  border: 1px solid #000;
  overflow: hidden;
  background: url('../../../Css/img/bg-heading.jpg') repeat;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
.detail-content .head {
  display: block;
  overflow: hidden;
  width: 100%;
  background: url('../../../Css/img/bg-head-detail.jpg') repeat right top;
  position: relative;
  padding-bottom: 10px;
}
/*.detail-content .head:before {
  content: "";
  display: block;
  overflow: hidden;
  width: 75px;
  height: 75px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background: url('../../../Css/img/emblem.png') no-repeat;
}*/
    .detail-content .head h3 {
        color: #ffe184;
        font-size: 24px;
        font-weight: bold;
        margin-top: 14px;
        padding-left: 40px;

        @media (max-width:612px) {
            padding-left: 30px;
        }

        @media (max-width:412px) {
            padding-left: 20px;
        }
    }

.detail-content .head .breadcrums {
  color: #fff;
  font-size: 14px;
  padding-left: 40px;

  @media (max-width:480px) {
      padding-left: 20px;
  }
}
.detail-content .head .breadcrums a {
  text-decoration: none;
  color: #fff;
}
a {
  color: #b22222;
}
.detail-content .body {
  padding: 20px 20px 20px;
}
.detail-content .body .nav {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
.detail-content .body .nav ul {
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 320px;
}
.detail-content .body .nav ul li {
  display: inline-block;
  position: relative;
  flex: 1;
}
.detail-content .body .nav ul li a {
  display: block;
  overflow: hidden;
  text-align: center;
  line-height: 45px;
  height: 45px;
  text-decoration: none;
  color: #fffcb7;
  font-size: 16px;
  padding-right: 12px;
}
.detail-content .body .nav ul li:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: url('../../../Css/img/bg-line-tab.png') no-repeat;
  width: 12px;
  height: 45px;
}
.detail-content .body .nav ul li {
  display: inline-block;
  position: relative;
  flex: 1;
}
.detail-content .body .nav:after {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(66, 61, 44, 1);
  background: -moz-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(66, 61, 44, 1)),
    color-stop(55%, rgba(110, 103, 62, 1)),
    color-stop(100%, rgba(66, 61, 44, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#423d2c', endColorstr='#423d2c', GradientType=1 );
}
.detail-content .body .items {
  display: block;
  overflow: hidden;
  margin-bottom: 20px;
}
.detail-content .body .item {
  display: flex;
  border-bottom: 1px solid #4a4949;
  padding: 20px 0;
  justify-content: space-between;
}
.detail-content .body .item .cover {
  margin-right: 10px;
  flex: 0 0 114px;
  max-width: 114px;
}
.detail-content .body .item .cover img {
  width: 100%;
}
.detail-content .body .item .detail {
  flex: 1;
  overflow: hidden;
}
.detail-content .body .item .detail h3 {

  margin-bottom: 5px;
}
.detail-content .body .item .detail h3 a {
  text-decoration: none;
  color: #ffe58b;
  font-size: 14px;
}
.detail-content .body .item .detail p {
  color: #fff;
  font-size: 12px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.paging {
  display: block;
  overflow: hidden;
  width: 100%;
}
.paging .paging-inner {
  text-align: center;
}
.paging ul {
  display: inline-block;
}
.paging ul li {
  display: inline-block;
  vertical-align: middle;
}
.paging ul li a {
  display: block;
  overflow: hidden;
  line-height: 22px;
  width: 22px;
  text-decoration: none;
  background-color: #373432;
  color: #767676;
  margin: 0 4px;
}
li.active{
  color: #ffe184;
}