.histories {
  margin-top: 5px;
  width: 100%;
  overflow-x: auto;
  .breakLine {
    width: 20%;
    height: 1px;
    background-color: #ccc;
    margin: 4px 0;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    .title {
      color: rgb(180, 219, 189);
      font-size: 15px;
    }
    .total {
      color: rgb(196, 155, 49);
      font-size: 18px;
      margin-top: 0px;
    }
  }
  table {
    font-size: 13px;

    thead {
      tr {
        @media screen and (max-width: 450px) {
          th {
            min-width: 130px !important;
          }
        }

        th {
          width: fit-content;
          padding: 0px 10px 0px 0px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0px 10px 0px 0px;
        }
      }
    }
  }
}
