@media only screen and (max-width: 680px){
    .support {
        flex-direction: column !important;
    }
    .support .rrrrrr {
        width: 100% !important;
        flex: 1.15;
        background: #f6f7f9;
        margin-top: 5px;
    }

    .support .llllll{
        padding-right: 0 !important;
    }
    
    .fb_iframe_widget span {
        margin: 20px 0 20px 20px !important;
    }
}

@media screen and (min-width: 681px) and (max-width: 980px)
{

    .support {
        display: block;
    }
    .support .llllll {
        margin: 5px auto;
    }
}
.support {
    margin-top: 4px;
    overflow: hidden;
    display: flex;
}
.support .llllll {
    flex: 1;
    padding-right: 5px;
    margin-top: 5px;
    text-align: center;
}
.support .llllll .block-event img{
    width: 100%;
}
.support .llllll .block-video a img{
    width: 100%;
}
.support .rrrrrr {
    width: 0;
    flex: 1.15;
    background: #f6f7f9;
    margin-top: 5px;
}
.support .block-social {
    background: url('../../../Css/img/bg-social.jpg') repeat-x #f6f7f9;
    border: 1px solid #110d07;
}
.support h3 {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    position: relative;
    line-height: 27px;
    padding-left: 20px;
    margin: 0 10px;
}
.support h3:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    overflow: hidden;
    width: 12px;
    height: 12px;
    background: url('../../../Css/img/dot-color.png') no-repeat;
}
.fb_iframe_widget {
    display: inline-block;
    position: relative;
}
.fb_iframe_widget span {
    display: inline-block;
    position: relative;
    text-align: justify;
}
.fb_iframe_widget iframe {
    position: absolute;
}

.support-iframe  {
    border: 1px solid #1a1a19 ;
    margin-top: 5px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  