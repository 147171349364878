.wrapmain {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @media screen and (max-width:980px) {
    .main{
      padding-top: 100px !important;
      .contentMain{
        flex-direction: column;
        .contentLeft {
          order: 2;
          .optionsButton{
            width: fit-content;
            margin: 20% auto 0 auto !important; 
          }
          .daily{
            margin-left: 0 !important;
          }

          .supportSocial{
            margin-left: 0 !important;
          }
        }
        .contentRightHome{
          order: 3;
          margin-left: auto !important;
          margin-right: auto!important;
          .contentRight{
            position: static !important;   
            margin: auto 0;
          }
        }
        .contentCenter{
          order: 1;
          margin: 0px !important;
          padding: 3px;
        }
      }
    }
  }
  // @media screen and (max-width: 991px) {
  //   .main{
  //     padding-top: 100px !important;
  //   }
  // }
  .main {
    background-image: url("../../../Css/img/bg-trung-thu-2023.jpg");
    width: 100%;

    background-color: #000;
    background-repeat: no-repeat;
    padding-top: 500px;
    .contentMain {
      display: flex;
      margin: 0 auto ;
      width: 100%;
      max-width: 940px;
      position: relative;
      height: 100%;
      .contentRightHome{
        .contentRight{
          position: fixed;
          top: 386px;
          right: 3%;
          display: block;
          background: url('../../../Css/img/bg-menu-right-2.png') no-repeat;
          overflow: hidden;
          width: 239px;
          height: 355px;
          z-index: 1;
  
          .inner{
            display: block;
            overflow: hidden;
            margin: 55px 0 0 66px;
  
            .download{
              height: 85px;
              background-position: -66px -55px;
            }
            
            .download:hover {
              background-position: -305px -55px;
            }
            
            a{
              display: block;
              overflow: hidden;
              background: url('../../../Css/img/text-menu-right-2.png') no-repeat;
              width: 160px;
              margin-bottom: 4px;
              margin-left: 0;
              border: 0;
              color: #b22222;
            }
  
            .trade {
              height: 49px;
              background-position: -66px -143px;
            }
  
            .trade:hover {
              background-position: -305px -143px;
            }
  
            .fanpage {
              height: 49px;
              background-position: -66px -199px;
            }
  
            .fanpage:hover {
              background-position: -305px -199px;
            }
            .group {
              position: relative;
              line-height: 50px;
            }
            .group {
              height: 55px;
              background-position: -66px -252px;
            }
            .group:hover {
              background-position: -305px -252px;
            }
            .totop {
              display: block;
              overflow: hidden;
              background: url('../../../Css/img/top.png') no-repeat;
              width: 165px;
              height: 47px;
            }
          }
        }
      }
      .contentLeft {
        .optionsButton{
          .blockUserAcount {
            display: block;
            position: relative;
            padding: 8px 0 0 8px;
            height: 280px;
            width: 100%;
            .download {
              cursor: pointer;
              display: block;
              overflow: hidden;
              width: 310px;
              height: 160px;
              position: absolute;
              top: 4px;
              left: 4px;
              z-index: 3;
            }
            .clip {
            }
            .reglog {
              overflow: hidden;
  
              display: flex;
              position: relative;
              z-index: 3;
              width: 299px;
              border-bottom: 4px solid #000;
              justify-content: space-between;
              margin-top: 4px;
              .reg {
                display: block;
                overflow: hidden;
                background: url("../../../Css/img/reg.jpg") no-repeat;
  
                flex: 0 0 149px;
  
                height: 65px;
                border-right: 2px solid #000;
              }
              .log {
                display: block;
                overflow: hidden;
  
                background: url("../../../Css/img/log.jpg") no-repeat;
                flex: 0 0 149px;
                height: 65px;
              }
              .reg:hover,
              .log:hover {
                background-position: 0 -66px;
              }
            }
            .userAccount {
              display: block;
              width: 299px;
              height: 34px;
              z-index: 3;
              position: absolute;
              bottom: 15px;
              left: 8px;
              background: url("../../../Css/img/user-acc.jpg") no-repeat;
            }
  
            .userAccount:hover {
              background-position: 0 -34px;
            }
          }
          .blockUserAcount::before {
            content: "";
            display: block;
            background: url("../../../Css/img/bg-left.png") no-repeat;
            width: 315px;
            height: 277px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .blockUserAcount::after {
            content: "";
            display: block;
            background: url("../../../Css/img/dragon.png") no-repeat;
            width: 341px;
            height: 257px;
            position: absolute;
            top: -130px;
            left: -92px;
            z-index: 2;
          }
  
        }
        
        .group {
          display: block;
          position: relative;
          width: 100%;
          border: 1px solid #000;

          background: #f6f7f9 url("../../../Css/img/bg-heading.jpg") repeat-x 0
            0;
          .h3 {
            line-height: 30px;
            position: relative;
            padding-left: 30px;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
          }
          .h3::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            display: block;
            overflow: hidden;
            width: 12px;
            height: 12px;
            background: url("../../../Css/img/dot-color.png") no-repeat;
          }
        }
        .daily {
          margin-top: 10px;
          display: block;
          position: relative;
          width: 100%;
          margin-left: 4px;
          margin-bottom: 10px;

          border: 1px solid #000;
          background: url("../../../Css/img/bg-heading.jpg") repeat 0 0;
          h3 {
            text-align: center;
            margin: 5px 15px;
            position: relative;
            color: #ffe992;
            height:30px;
          }
          h3::after {
            content: "";
            display: block;
            overflow: hidden;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: rgba(66, 61, 44, 1);
            background: -moz-linear-gradient(
              left,
              rgba(66, 61, 44, 1) 0%,
              rgba(110, 103, 62, 1) 55%,
              rgba(66, 61, 44, 1) 100%
            );
            background: -webkit-gradient(
              left top,
              right top,
              color-stop(0%, rgba(66, 61, 44, 1)),
              color-stop(55%, rgba(110, 103, 62, 1)),
              color-stop(100%, rgba(66, 61, 44, 1))
            );
            background: -webkit-linear-gradient(
              left,
              rgba(66, 61, 44, 1) 0%,
              rgba(110, 103, 62, 1) 55%,
              rgba(66, 61, 44, 1) 100%
            );
            background: -o-linear-gradient(
              left,
              rgba(66, 61, 44, 1) 0%,
              rgba(110, 103, 62, 1) 55%,
              rgba(66, 61, 44, 1) 100%
            );
            background: -ms-linear-gradient(
              left,
              rgba(66, 61, 44, 1) 0%,
              rgba(110, 103, 62, 1) 55%,
              rgba(66, 61, 44, 1) 100%
            );
            background: linear-gradient(
              to right,
              rgba(66, 61, 44, 1) 0%,
              rgba(110, 103, 62, 1) 55%,
              rgba(66, 61, 44, 1) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#423d2c', endColorstr='#423d2c', GradientType=1 );
          }
          .dailyInner {
            padding: 0 15px 20px 15px;
            position: relative;
            .tabs {
              display: block;
              overflow: hidden;
              margin-top: 8px;
              margin-bottom: 8px;
              ul {
                list-style: none;
                display: flex;
                .tabsItem {
                  border: none !important;
                  flex: 1;
                  text-align: center;
                  background-color: #3f3f3f;
                  a {
                    display: block;
                    font-size: 14px;
                    font-weight: 700;
                    color: #ffe992;
                    text-decoration: none;
                    line-height: 29px;
                  }
                }
                .tabsItem:hover {
                  background-color: #656565;
                }
                .tabsItem.active {
                  background-color: #656565;
                }
              }
            }
            .dailyContent {
              .day {
                .content {
                  width: 100%;
                  min-height: 368px;
                  display: block;
                  overflow: hidden;
                  .item {
                    display: flex;

                    .name {
                      flex: 1;
                      font-size: 14px;
                      color: #fff;
                      line-height: 24px;
                    }
                    .time {
                      flex: 0 0 77px;
                      max-width: 77px;
                      line-height: 24px;
                      text-align: right;
                      font-size: 12px;
                      color: #bba755;
                    }
                  }
                }
              }
            }
          }
          .dailyInner::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            overflow: hidden;
            width: 23px;
            height: 22px;
            background: url("../../../Css/img/corner-left.png") no-repeat;
          }
          .dailyInner::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            overflow: hidden;
            width: 23px;
            height: 22px;
            background: url("../../../Css/img/corner-right.png") no-repeat;
          }
        }
        .daily::before {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-top-left.png") no-repeat;
        }
        .daily::after {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          top: 0;
          right: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-top-right.png") no-repeat;
        }
        .supportSocial{
          margin-left: 4px;
          width: 100%;
          background: #f6f7f9;
          margin-top: 5px;
          .blockSocial{
            background: url('../../../Css/img/bg-social.jpg') repeat-x #f6f7f9;
            border: 1px solid #110d07;
            h3 {
              font-size: 12px;
              font-weight: 700;
              color: #fff;
              position: relative;
              line-height: 27px;
              padding-left: 20px;
              margin: 0 10px;
            }
            h3:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              display: block;
              overflow: hidden;
              width: 12px;
              height: 12px;
              background: url('../../../Css/img/dot-color.png') no-repeat;
            }
          }
        }
      }
      .contentCenter {
        flex: 1;
        display: block;
        overflow: hidden;
        margin: 5px 0 0 10px;
      }
    }

    .popupWrap {
      background-color: transparent;
      height: 820px;
      .container {
        height: 100%;
        position: relative;

        .menuWrap {
          position: fixed;
          right: 10px;
          top: 140px;
          height: 500px;
          z-index: 100;
          opacity: 0.9;
          display: flex;
          justify-content: center;

          .menu {
          }
          .wrapForgetPass {
            position: absolute;
            left: 24%;
            top: 5%;
            border-radius: 10px;
            width: 62%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass {
              border-radius: 10px;
              height: 100%;
              width: 100%;
              filter: brightness(1.3);
            }
            .textForgetPass {
              text-align: center;
              font-size: 1.3rem;
              position: absolute;
              font-weight: bold;
              color: white;
            }
            .textForgetPass:hover .btnForgetPass {
              filter: brightness(1);
            }
            .btnForgetPass:hover,
            .textForgetPass:hover {
              filter: brightness(1);
            }
          }
          .wrapLogin {
            position: absolute;
            left: 24%;
            top: calc(5% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass {
              border-radius: 10px;
              height: 100%;
              width: 100%;
              filter: brightness(1.3);
            }
            .textForgetPass {
              text-align: center;
              font-size: 1.3rem;
              position: absolute;
              font-weight: bold;
              color: white;
            }
            .textForgetPass:hover .btnForgetPass {
              filter: brightness(1);
            }
            .btnForgetPass:hover,
            .textForgetPass:hover {
              filter: brightness(1);
            }
          }
          .wrapSignup {
            position: absolute;
            left: 24%;
            top: calc(16% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass {
              border-radius: 10px;
              height: 100%;
              width: 100%;
              filter: brightness(1.3);
            }
            .textForgetPass {
              text-align: center;
              font-size: 1.3rem;
              position: absolute;
              font-weight: bold;
              color: white;
            }
            .textForgetPass:hover .btnForgetPass {
              filter: brightness(1);
            }
            .btnForgetPass:hover,
            .textForgetPass:hover {
              filter: brightness(1);
            }
          }
          .wrapbtnDownApp {
            position: absolute;
            left: 24%;
            top: calc(27% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btndow {
              margin: 2px;
              height: 50px;
              border-radius: 10px;
              filter: brightness(0.9);
            }
            .btndow:hover {
              filter: brightness(1);
            }
          }
          .wrapbtnDownPlay {
            position: absolute;
            left: 24%;
            top: calc(38% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btndow {
              margin: 2px;
              height: 50px;
              border-radius: 10px;
              filter: brightness(0.9);
            }
            .btndow:hover {
              filter: brightness(1);
            }
          }
          .wrapbtnDownAPK {
            position: absolute;
            left: 24%;
            top: calc(49% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btndow {
              margin: 2px;
              height: 50px;
              border-radius: 10px;
              filter: brightness(0.9);
            }
            .btndow:hover {
              filter: brightness(1);
            }
          }
          .wrapbtnManualDownApp {
            position: absolute;
            left: 24%;
            top: calc(60% + 103px);
            border-radius: 10px;
            width: 62%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass {
              border-radius: 10px;
              height: 100%;
              width: 100%;
              filter: brightness(1.3);
            }
            .textForgetPass {
              text-align: center;
              font-size: 1.3rem;
              position: absolute;
              font-weight: bold;
              color: white;
            }
            .textForgetPass:hover .btnForgetPass {
              filter: brightness(1);
            }
            .btnForgetPass:hover,
            .textForgetPass:hover {
              filter: brightness(1);
            }
          }
        }
        @media (max-width: 1060px) {
          .menuWrap {
            display: none;
          }
          .imgWrap {
            // height: 500px !important;
            // width: 50%;
          }
        }
        @media (max-width: 411px) {
          .imgWrap {
            height: 500px !important;
            width: 100%;
          }
        }
        @media (max-width: 612px) {
          .row {
            padding-top: 100px;
            align-items: start !important;
            margin: 0px;
            .imgWrap {
              height: 450px !important;
              width: auto !important;

              .img {
                flex-direction: column;
                width: 100%;

                min-width: 80px;
                background-size: 100% 100% !important;
                padding: 10px 8px;
                .btnNapWrap {
                  padding: 5px 0px !important;
                  width: 100px !important;
                  height: auto !important;
                }
                .btnNapWrapDown {
                  width: 100px !important;
                  height: auto !important;
                  img {
                    width: 100% !important;
                    height: auto !important;
                  }
                }
                .codeWrap {
                  width: 100px !important;
                  .btnCodeWrap {
                    width: 100% !important;
                    align-items: center !important;
                    justify-content: center !important;
                    img {
                      margin: 0px !important;
                      width: 100% !important;
                      height: auto !important;
                    }
                    .btnCodeText {
                      width: 70% !important;
                      height: auto !important;
                      margin: auto !important;
                    }
                  }
                }
              }
            }
          }
        }
        .row {
          height: 100%;
          display: flex;
          justify-content: end;
          align-items: end;
          width: 100%;

          .imgWrap {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: end;
            height: 240px;
            .img {
              background-image: url("../../../Css/img/bg-block-header-v2.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 600px 230px;
              // min-width: 600px;
              min-height: 210px;
              height: auto !important;
              display: flex;
              justify-content: center;
              align-items: center;
              .btnNapWrap {
                width: auto;
                height: 230px;
                padding: 10px 2px 10px 5px;
                filter: brightness(0.9);
                .btnNap {
                  height: calc(100% - 5px);
                  width: auto;
                  transition: filter 0.3s ease;
                  border-radius: 5px;
                }
              }
              .btnNapWrap:hover {
                filter: brightness(1);
              }
              .btnNapWrapDown {
                height: 100%;
                display: flex;
                flex-direction: column;
                .btndow {
                  margin: 2px;
                  height: 65px;
                  border-radius: 10px;
                  filter: brightness(0.9);
                }
                .btndow:hover {
                  filter: brightness(1);
                }
              }
              .codeWrap {
                height: 100%;
                width: auto;
                display: flex;
                flex-direction: column;
                .btnCodeWrap {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  .btnCode {
                    width: 193px;
                    height: calc(67px * 2);
                    padding: 2px;
                    border-radius: 12px;
                    margin-bottom: 4px;
                    filter: brightness(0.9);
                  }
                  .btnCodeText {
                    display: inline-block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    transition: all 0.3s;
                    // width: 193px;
                    // height: calc(67px * 2 );
                    // padding: 2px ;
                    // border-radius: 12px;
                    // margin-bottom: 4px;
                    // filter: brightness(0.9);
                  }
                }

                .btndk {
                  height: 65px;
                  width: 193px;
                  filter: brightness(0.9);
                  // padding: 10px 2px 10px 2px;
                  border-radius: 10px;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  .btnCodeText {
                    display: inline-block;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    transition: all 0.3s;
                    // width: 193px;
                    // height: calc(67px * 2 );
                    // padding: 2px ;
                    // border-radius: 12px;
                    // margin-bottom: 4px;
                    // filter: brightness(0.9);
                  }
                }

                .btnCode:hover,
                .btndk:hover {
                  filter: brightness(1);
                }
              }
            }
          }
        }
      }
    }
  }
}

.Body0 {
  height: auto;
  height: 600px;
  background-image: url("../../../Css/img/mainnews.png");

  background-repeat: no-repeat;
  background-position: left;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  align-items: end;
  padding: 10px 0px 0px 0px;
  position: relative;
  .btgRe {
    background-image: url("../../../Css/img/bgNew.PNG");
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;
    height: 700px;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
  }
  .imgListNews {
    position: absolute;
  }
  .Body0Wrap {
    width: 100%;
    margin: 0px;
    .contentWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .content {
        opacity: 0.8;
      }
      .bd0TopContent {
        font-family: "Courier New", Courier, monospace;
        transform: rotate(-90deg);
        font-size: 1rem;
        font-weight: 600;
        display: inline-block;
        color: #082f34;
        margin-right: 80%;
        white-space: nowrap;
      }
    }
    .mainWrap {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 40px;
      padding-bottom: 0px;
      .TitleNewsWrap {
        width: 80%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        .bftitleNews {
          filter: brightness(1.4);
          opacity: 0.9;
          width: 70%;
        }
        .titleNews {
          position: absolute;
          font-size: 1.5rem;
          color: rgb(255, 255, 255, 0.7);
        }
      }
      .cardWrap {
        width: 60%;
        padding: 0px;
        cursor: pointer;
        .WrapIn {
          padding: 20px 20px 0px 10px;
          position: relative;
          width: 100%;
          border-bottom: 1px solid rgb(255, 255, 255, 0.6);
          .imgWrap {
            position: absolute;
            width: 30%;
            .img {
              width: 100%;
              height: auto;
            }
          }
          .contenWrap {
            display: flex;
            justify-content: space-between;
            .content {
              font-size: 1rem;
              text-transform: capitalize;
              font-weight: 600;
              line-height: 50px;
              color: #faebd9;
            }
            .see {
              font-size: 1rem;
              text-transform: capitalize;
              font-weight: 600;
              line-height: 50px;
              color: #faebd9;
            }
          }
        }
        .WrapIn:hover {
          .contenWrap {
            .content {
              opacity: 1;
            }
          }
        }
      }
    }
    .Seemore {
      text-decoration: none;
      width: 60%;
      text-align: end;
      // line-height: 50px;
      color: #faebd9;
      cursor: pointer;
    }
  }
}

.Body1 {
  background-image: url("../../../Css/img/bg-bxh.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
  .backGBody1 {
    z-index: 1;
    top: 0;
    position: absolute;
    width: 100%;
    height: auto;
  }
  .Criept {
    position: relative;
    z-index: 2;
    width: 70%;

    height: 100%;
    .bd1Title {
      padding-top: 100px;
      white-space: pre-line;
    }
  }
  .bgNew {
    background-image: url("../../../Css/img/bg-main-sub.jpg");

    width: 80%;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    .mainNews {
      // bg-main-sub.jpg
      // platforms_bg.34b89957.jpg

      // background-repeat: repeat;

      margin-top: 140px;

      position: relative;
      padding: 20px 30px;
      padding-top: 50px;
      z-index: 10;

      width: 100%;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      max-height: 1100px;
      overflow-y: scroll;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
