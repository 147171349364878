

/* @media screen and (max-width:1700px) {
    .content-right{
        display: none !important;
    }
} */
@media screen and (max-width:767.98px) {
    .content-right{
        display: none !important;
    }
}
.content-right {
    position: fixed;
    top: 386px;
    right: 3%;
    display: block;
    background: url('../../../Css/img/bg-menu-right-2.png') no-repeat;
    overflow: hidden;
    width: 239px;
    height: 355px;
    z-index: 1;
}
.content-right .inner {
    display: block;
    overflow: hidden;
    margin: 55px 0 0 66px;
}
.content-right .download {
    height: 85px;
    background-position: -66px -55px;
}
.content-right a {
    display: block;
    overflow: hidden;
    background: url('../../../Css/img/text-menu-right-2.png') no-repeat;
    width: 160px;
    margin-bottom: 4px;
    margin-left: 0;
    border: 0;
}
.content-right .trade {
    height: 49px;
    background-position: -66px -143px;
}
.content-right .fanpage {
    height: 49px;
    background-position: -66px -199px;
}
.group {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
    border: 1px solid #000;
    line-height: 50px;
    background: #f6f7f9 url('../../../Css/img/bg-heading.jpg') repeat-x 0 0;
}
.content-right .group {
    height: 55px;
    background-position: -66px -252px;
}
a {
    color: #b22222;
}
.content-right .totop {
    display: block;
    overflow: hidden;
    background: url('../../../Css/img/top.png') no-repeat;
    width: 165px;
    height: 47px;
}
.content-right .download:hover {
    background-position: -305px -55px;
}
.content-right .trade:hover {
    background-position: -305px -143px;
}
.content-right .fanpage:hover {
    background-position: -305px -199px;
}
.content-right .group:hover {
    background-position: -305px -252px;
}