.wrapBox {
  margin-bottom: 15px;

  .wrapItem {
    display: flex;
    flex-direction: column;
    .label {
      color: white;
    }
    .input {
      padding: 2px;
      color: black;
      width: 100%;
    }
  }
}
