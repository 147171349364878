@media screen and (max-width: 480px) {
  .wrapperHome {
    background-size: 220% 15% !important;
    background-color: rgba(26, 22, 11, 255) !important;
    padding-top: 100px !important;
    min-height: 0 !important;
    background-position: 100% -2% !important;
  }
}

.wrapperHome {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: auto;
  background-color: #000;
  padding-top: 400px;
  background: url("../../../Css/img/header-sub.jpg") no-repeat center 0;
  background-size: 100%;
  background-color: rgba(25, 21, 10, 255);
  min-height: 100vh;

  .wrapperInner {
    display: block;
    width: 100%;
    margin: 0 auto;

    .contentMain {
      display: flex;
      margin: 0 auto 15px;
      width: 100%;
      max-width: 940px;
      position: relative;

      .detailContent {
        width: 100% !important;
        border: 1px solid #000;
        overflow: hidden;
        background: url("../../../Css/img/bg-heading.jpg") repeat;

        .head {
          display: block;
          overflow: hidden;
          width: 100%;
          background: url("../../../Css/img/bg-head-detail.jpg") repeat right
            top;
          position: relative;
          padding-bottom: 10px;

          h3 {
            color: #ffe184;
            font-size: 24px;
            font-weight: bold;
            margin-top: 14px;
            padding-left: 100px;
          }
        }

        .head:before {
          content: "";
          display: block;
          overflow: hidden;
          width: 75px;
          height: 75px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          background: url("../../../Css/img/emblem.png") no-repeat;
        }

        .body {
          padding: 10px;
          .img {
            width: 100%;
          }

          .btnSubmit {
            align-items: center;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            width: 100%;

            .btn {
              height: fit-content;
              padding: 5px;
              font-size: 18px;
              margin-right: 10px;
              color: #5f3800;
              border-top: 2px solid #ebdeb1;
              border-right: 2px solid #bb985d;
              border-bottom: 2px solid #ae8334;
              border-left: 2px solid #bb985d;
              background-image: linear-gradient(to top, #f0b465, #dbd5c6);
            }
          }
          @media screen and (max-width: 651px) {
            .btnSubmit {
              margin-top: 50px !important;
            }
          }
        }

        .newDetailIframe {
          border: 1px solid #1a1a19;
          margin: 0px auto 10px auto;
          max-width: 100%;
          position: relative;
          overflow: hidden;
          width: 96%;
          padding-top: 56.25%;
          .responsiveIframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
