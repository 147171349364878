@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "poppins", sans-serif;
}

@media screen and (max-width: 991.98px)
{
  .main {
    padding-top: 100px !important;
  }


  .contentLeft {
     display: none !important;
 }
}

.main {
  background-image: url("../../../Css/img/bg-trung-thu-2023.jpg");
  width: 100%;
    min-height: 100vh;
  background-size: cover;
  padding-top: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  .contentMain {
    display: flex;
    margin: 0 auto 15px;
    width: 100%;
    max-width: 640px;
    position: relative;
    .contentLeft {
      flex: 0 0 305px;
      display: block;
      margin-right: 15px;
      max-width: 305px;
      width: 100%;
      .iframeId {
        margin-top: 5px;
        position: relative;
        background: #000 url("../../../Css/img/bg-heading.jpg") top left repeat;
        .wrapIframe {
          position: relative;
          padding: 10px;
          position: relative;
          ul {
            margin-top: 0;
            margin-bottom: 10px;
            li {
              display: block;
              width: 100%;
              height: 50px;
              margin: 5px auto 0 auto;
              padding-left: 8px;
              background: url("../../../Css/img/btn-left.png") top left no-repeat;
              a {
                text-align: center;
                font-weight: bold;
                display: block;
                color: #ff9239;
                line-height: 50px;
                background: url("../../../Css/img/btn-right.png") top right no-repeat;
                text-decoration: none;
              }
            }
            li:first-child {
              margin-top: 0px;
            }
            li:hover a {
              color: #ff9239;
              text-decoration: none;
              background-position: bottom right;
            }
          }
        }
        .wrapIframe::before {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-left.png") no-repeat;
        }
        .wrapIframe::after {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-right.png") no-repeat;
        }
      }
      .iframeId::before {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 23px;
        height: 22px;
        background: url("../../../Css/img/corner-top-left.png") no-repeat;
      }
      .iframeId::after {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 23px;
        height: 22px;
        background: url("../../../Css/img/corner-top-right.png") no-repeat;
      }
    }
    .contentCenter {
      flex: 1;
      display: block;
      overflow: hidden;
      margin: 5px 0 0 0;
      .iframeId {
        position: relative;
        background: #000 url("../../../Css/img/bg-heading.jpg") top left repeat;
        .wrapIframe {
          position: relative;
          padding: 10px;
          position: relative;
          .form {
            max-width: 375px;
            margin: 0 auto;
            padding-bottom: 50px;
            color: #d2a763;
            margin-top: 15px;
            button,
            input,
            select,
            textarea {
              font-family: inherit;
              font-size: inherit;
              line-height: inherit;
            }
            .h2,
            h2 {
              font-size: 30px;
            }
            .hr {
              position: relative;
              text-align: center;
            }
            .hr:after {
              content: "";
              display: block;
              border-bottom: 1px solid #564216;
              position: absolute;
              top: 0;
              bottom: 8px;
              right: 0;
              width: 100%;
            }
            .formGroup {
                margin-top:  15px;
              margin-bottom: 15px;
              display: flex;
              flex-direction: column;
              
              label {
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 700;
              }
              .formControl {
                padding: 5px 10px ;
                background: #000;
                color: #d2a763;
                border-color: #564216;
                border-radius: 0px;
                -webkit-box-shadow: none;
                box-shadow: none;
                overflow: hidden;
              
              }
              .btn{
                max-width: 100px;
                padding: 10px;
                color: #5f3800;
                border-top: 2px solid #ebdeb1;
                border-right: 2px solid #bb985d;
                border-bottom: 2px solid #ae8334;
                border-left: 2px solid #bb985d;
                background-image: linear-gradient(to top, #b99366, #e0d2af);
                
              }
              .btn:hover {
                color: #5f3800;
                border-top: 2px solid #ebdeb1;
                border-right: 2px solid #bb985d;
                border-bottom: 2px solid #ae8334;
                border-left: 2px solid #bb985d;
                background-image: linear-gradient(to top, #ebc04f, #fef1d0);
              }
            }
          }
        }
        .wrapIframe::before {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-left.png") no-repeat;
        }
        .wrapIframe::after {
          content: "";
          display: block;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 23px;
          height: 22px;
          background: url("../../../Css/img/corner-right.png") no-repeat;
        }
      }
      .iframeId::before {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 23px;
        height: 22px;
        background: url("../../../Css/img/corner-top-left.png") no-repeat;
      }
      .iframeId::after {
        content: "";
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        width: 23px;
        height: 22px;
        background: url("../../../Css/img/corner-top-right.png") no-repeat;
      }
    }
  }

}
