.main{
    height: 90vh;
    .topMain{
        border-bottom: 1px solid #e4e4e4;
        .topMainTitle{
            color: black;
            font-size: 1.7rem;
            font-weight: 400;
            padding-right: 10px;
            display: inline;
            
        }
        .topMaincontentWrap{
         
                display: inline;
                .topMaincontent{
                    
                position: relative;
            
                font-size: 0.8rem;
                color: #333333;
                opacity: 0.7;
                cursor: pointer;
            }
            .topMaincontent::after{
                margin-left:0.6rem ;
                margin-right:0.6rem ;
                content: "|";
               
            }
            .topMaincontent:hover{
                color: var(--adminMainColor);
                
            }
        }
        .topMaincontentWrap .topMaincontent:last-child:after {
                content: "";
               
        }
       
      
    }
    .bodyMain{
        
        width: 100%;
        // padding: 50px 0px;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: white !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        .bodyMainTop
        {
            width: 100%;
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:  10px 10px;
            .btn{

                text-decoration: none;
                display: inline-block;
                justify-content: center;
                align-items: center;
                background-color:  var(--adminMainColor) ;
                opacity: 0.8;
                padding:10px 10px;
                border-radius: 10px;
                font-size: 1rem;
                opacity: 0.7;
                font-weight: bold;
                color: white;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                transition: all 0.5s;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
            }
            .btn:hover{
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                opacity: 1;
               
            }
            .filterWrap{
                display: flex;
                align-items: center;
                .searchWrap{
                    display: inline-block;
                    border-radius: 10px;
                    border: 1px solid gray;
                    overflow: hidden;
                    height: 100%;
                    padding: 4px 0px;
                    
                    opacity: 0.5;
                    .Search{
                        border: none;
                        padding:2px 5px;
                    }
                    .Search:focus{
                        outline: gray;
                    }
                    .contentSearch{
                        // border-left: 1px  solid #333333 ;
                        line-height: 26px;
                        height: 100%;
                        position: relative;
                        display: inline-block;
                        font-size: 1rem;
                        color: #424242;
                        padding: 0px 10px;
                        opacity: 0.5;
                        // opacity: 0.8;
                        // background-color:  var(--adminMainColor);
                    }
                    .contentSearch::after{
                        content: '|';
                        z-index: 5;
                        position: absolute;
                       
                        left: 0;
                    }
                }
                .selectBlock{
                    width: 150px;
                    margin-left: 6px;
                    .select{
                        font-size: 0.8rem;
                        border-radius: 50px;
                        outline: none !important;
                        border: none !important;
                        padding: 0px;
                    }
                    .select:focus{
                        outline: none !important;
                    }
                }
                .btnClear{
                    margin-left: 10px;
                    border-radius: 15px;
                   
                    line-height: 1.8rem;
                    font-size: 0.7rem;
                    padding: 5px ;
                    cursor: pointer;
                }
                .btnClear:hover{
                    background-color: rgba(66, 66, 66,0.2);
                    color: white;
                }
                
                
                
            }
           
        }
        .mainWrap{
           
            .row{
                .listFileWrap{
                    margin-top: 50px;
                    position: relative;   
                    display: flex;
                    justify-content: start;    
                    flex-direction: column;
                    align-items: space-between;
                    .body{
                       
                        @media screen and (max-width:768px) {
                            .bodyCsrRow{
                                flex-direction: column;
                                .cardWrap1{
                                    width: 100%;
                                }
                            }
                        }
                        .bodyCsrRow{
                                display: none;
                                opacity: 0;
                                transition: all 0.5s;
                                cursor: pointer;
                                
                            .cardWrap1{
                                margin-bottom: 20px;
                               position: relative;
                               transition: all 0.5s;
                                .WrapBody{
                                    padding: 5px;
                                    box-shadow: rgba(99, 25, 184, 0.15) 0px 5px 15px 0px;
                                    height: 250px;
                                    background-color: white;
                                    border-radius: 10px;
                                    display: flex;
                                    flex-direction: column;
                                    position: relative;
                                    .imgWrap{
                                        
                                        width: 100%;
                                        height: 50%;
                                        overflow-y: hidden;
                                        .imgSrc{
                                            padding: 5px;
                                            box-shadow: rgb(99, 25, 184,0.15) 3px 3px 6px 0px inset, rgba(99, 25, 184, 0.03) -3px -3px 6px 1px inset;
                                            top:-10px;
                                            left:-10px;
                                            width: 100%;
                                        }
                                    }
                                    .wrapTitle{
                                        padding: 5px;
                                        height: 50%;
                                        overflow-y: scroll;
                                        .titel{
                                            word-wrap: break-word;
                                           font-size: 1rem;
                                           font-weight: bold;
                                           opacity: 1;
                                        }
                                        .discription{
                                            font-size: 1rem;
                                        }
                                    }
                                }
                                .WrapBody:hover{
                                    
                                    box-shadow: rgba(99, 25, 184, 0.4) 0px 5px 15px 0px;
                                    .imgSrc{
                                        box-shadow: rgb(99, 25, 184,0.2) 3px 3px 6px 0px inset, rgba(99, 25, 184, 0.08) -3px -3px 6px 1px inset;
                                    }
                                }
                               .icon{
                                    font-size: 1.6rem;
                                    position: absolute;
                                    opacity: 0.9;
                                    right: 20px;
                                    bottom: 5px;
                               }
                              
                            .warpDropFD1{
                                display: none;
                                overflow: hidden;
                                height: 130px;
                                z-index: 100;
                                top: calc(20%);
                                left: calc(40%) ;
                                position: absolute;
                                width: 180px;
                                transition: 0.3s all;
                                border-radius: 10px;
                                background-color: white;
                                // box-shadow: rgba(99, 25, 184,0.3) 0px 20px 30px -10px;
                                box-shadow: rgba(99, 25, 184, 0.35) 0px 5px 15px;
                                .itemWrap
                                {

                                    margin: 0px;
                                    padding: 0px;
                                    .item{
                                        margin: 10px 0px;
                                        padding: 4px;
                                       
                                        a{
                                            text-decoration: none;
                                            color: #333333;
                                        }
                                        .iconItem{
                                            font-size: 1rem;
                                            margin-left: 5px;
                                        }
                                        .contentItem{
                                            margin-left: 10px;
                                            font-size: 0.8rem;
                                        }
                                        .iconItemFight{
                                            margin-top: 5px;
                                        }
                                    }
                                    .item:hover{
                                        
                                        background-color: rgba(66, 66, 66,0.1);
                                    }
                                }
                            }
                            .warpDropFD1.active{
                                display: block;
                            }
                                
                            }
                            .cardWrap1:hover{
                                z-index: 1000;
                                transform: translateY(-5px) translatex(2px); 
                            }

                        }
                        .bodyEsrRow{
                            display: none;
                            opacity: 0;
                            transition: all 0.5s;
                            .cardWrap{
                                padding: 5px;
                                .cardinWrap{
                                    padding: 5px;
                                    box-shadow: rgba(99, 25, 184, 0.15) 0px 5px 15px 0px;
                                  
                                    background-color: white;
                                    border-radius: 10px;
                                 
                                 
                                    position: relative;
                                  
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    transition: all 0.5s;
                                  
                                  

                                        .cardImgWrap{
                                            width: 100%;
                                            height: 255px;
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            position: relative;
                                            .cardImg{
                                                width: 100%;
                                                height: 230px;
                                                border-radius: 10px;
                                            }
                                            .cardContent{
                                                background-color: rgba(99, 25, 184,0.2);
                                                border-radius: 0px 0px 10px 10px ;
                                                text-align: center;
                                                color: white;
                                                bottom: 0;
                                                font-size: 1rem;
                                                text-shadow:rgba(99, 25, 184, 1) 0px 5px 15px 0px;
                                            }
                                            
                                        }
                                        .warpDropFD2{
                                            display: none;
                                            overflow: hidden;
                                            height: 130px;
                                            z-index: 100;
                                            top: calc(20%);
                                            left: calc(40%) ;
                                            position: absolute;
                                            width: 180px;
                                            transition: 0.3s all;
                                            border-radius: 10px;
                                            background-color: white;
                                            // box-shadow: rgba(99, 25, 184,0.3) 0px 20px 30px -10px;
                                            box-shadow: rgba(99, 25, 184, 0.35) 0px 5px 15px;
                                            .itemWrap
                                            {
            
                                                margin: 0px;
                                                padding: 0px;
                                                .item{
                                                    margin: 10px 0px;
                                                    padding: 4px;
                                                   
                                                    .iconItem{
                                                        font-size: 1rem;
                                                    margin-left: 5px;
                                                    }
                                                    .contentItem{
                                                        margin-left: 10px;
                                                        font-size: 0.8rem;
                                                    }
                                                    .iconItemFight{
                                                        margin-top: 5px;
                                                    }
                                                }
                                                .item:hover{
                                                    
                                                    background-color: rgba(66, 66, 66,0.1);
                                                }
                                            }
                                        }
                                        .warpDropFD2.active{
                                            display: block;
                                        }
                                     
                                   
                                }
                                .cardinWrap:hover{
                                    z-index: 1000;
                                    transform: translateY(-5px) translatex(2px);
                                    box-shadow: rgba(99, 25, 184, 0.4) 0px 5px 15px 0px;
                                    .imgSrc{
                                        box-shadow: rgb(99, 25, 184,0.2) 3px 3px 6px 0px inset, rgba(99, 25, 184, 0.08) -3px -3px 6px 1px inset;
                                    }
                                }
                              
                            }
                        }
                        .bodyCsrRow.active{
                            display: flex;
                            opacity: 1;
                        }
                        .bodyEsrRow.active{
                            display: flex;
                            opacity: 1;
                        }
                    }
                    .footer
                    {
                        padding: 50px 0px;
                        display: flex;
                        justify-content: center;
                        .footerWrap
                        {
            
                            .Pagination
                            {
                                display: flex;
                                flex-direction: row;
                                justify-content:center;
                                align-items: center;
                                .prev{
                                    cursor: pointer;
                                    
                                    padding: 10px;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    opacity: 0.7;
                                    margin-right: 10px;
                                }
                                .prev:hover , .last:hover {
                                    opacity: 0.9;
                                }
                                .item{
                                    
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: white ;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 10px;
                                    font-size: 1.2rem;
                                    opacity: 0.7;
                                    font-weight: bold;
                                    color: #424242;
                                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                                    transition: all 0.5s;
                                    cursor: pointer;
                                }
                                .item.active{
                                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                                    background-color: #504178;
                                    color: white;
                                }
                                .item:hover{
                                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                                    background-color: #504178;
                                    color: white;
                                }
                                .last{
                                    padding: 10px;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    opacity: 0.7;
                                    margin-left: 10px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}