.loadingWrap{
    position: fixed;
    top: 0;
    height: 100vh;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(38, 38, 38,0.3);
    z-index: 1000;
}