.main{
    
    .headerWrap{
        border-bottom: none;
        
        .title{
            font-size: 2rem;
            font-weight: 600;
        }
    }
    .bodyWrap
    {
        padding: 2px;
        .from
        {
            height: 100%;
            background-color: #575757;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .container
            {
                
                .row{
                    .leftWrap{
                       
                     
                        border-radius: 5px;
                     
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .imgWrap{
                            border: 5px dashed #ffffff;
                            background-color: #6c6e6d;
                            border-radius: 50%;
                            height: 130px;
                            width: 130px;
                            margin-top: 10px;
                            margin-bottom: 30px;
                            .imgTitle{
                                font-size: 2rem;
                                color: #ffffff;
                                font-weight: bold;
                                text-align: center;
                                line-height: 110px;
                            }
                        }
                        .leftBody{
                            width: 100%;
                            color: #ffffff;
                            display: flex;
                            flex-direction: column;
                           
                                                    
                            .wrapbory{
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                position: relative;
                                .wrapItem{
                                    display: flex;
                                    flex-direction: column;
                                    .label{
                                        
                                    }
                                    .input{
                                        color: black;
                                        width: 100%;
                                        padding: 0px 4px;
                                        margin-bottom: 20px;
                                    }
                                    
                                }
                                p{
                                    color: rgb(183, 5, 5);
                                    margin-top: 1px;
                                    position: absolute;
                                    bottom: 0;
                                    font-size: 14px;
                                    width: 300px;
                                }
                            }
                           
                        }
                        .rightBody{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .lablePass{
                                    color: #ffffff;
                            }
                            .inputPass{
                                width: 100%;   
                            }
                            .type{
                                border-radius: 0px;
                                padding: 1px 2px;
                            }
                        }
                    }
                    .rightWrap{
                        position: relative;
                        padding: 40px 30px;
                        .closeIcon{
                            position: absolute;
                            right: 2px;
                            top: 2px;
                            cursor: pointer;
                            font-size: 1.1rem;
                        }
                        .closeIcon:hover{
                            color: rgb(125, 8, 8);
                        }
                        .usenameWrap{
                            .rightLabel{
                               
                            }
                            .input{
                                margin-left: 10px;
                                border-radius: 10px;
                                border: 1px solid gray;
                                padding: 2px 5px;
                            }
                            .input:focus{
                                outline: gray;
                            }
                            .btn{ 
                                margin-left: 5px;
                                display: inline-block;
                                justify-content: center;
                                align-items: center;
                                background-color:  var(--adminMainColor) ;
                                opacity: 0.8;
                                padding:4px 10px;
                                border-radius: 10px;
                                font-size: 1rem;
                                opacity: 0.7;
                                font-weight: bold;
                                color: white;
                                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                                transition: all 0.5s;
                                cursor: pointer;
                            }
                            .btn:hover{
                                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                                opacity: 1;
                               
                            }
                        }
                      
                        .AFLWrap{
                            display: flex;
                            flex-direction: row;
                            .rightLabel{
                                display: flex;
                                align-items: center;
                            }
                            .formRadioCheck{
                                display: flex;
                                flex-direction: row;
                                padding:20px 30px;
                                .formRadioCheck{
                                   
                                }
                            }
                        }
                        .selectPer{
                            margin-top: 10px;
                            // width: 100px;
                            // height: 50px;
                        }
                        .btnWrap{
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            display: flex;
                            justify-content: end;
                            .btnUpdate{
                                background-color: var(--adminMainColor);
                                color: #ffffff;
                            }
                        }
                    }
                    
                }
                .btnSubmit{
                    margin: 10px 0px 1px  0px;
                    color: white;
                    background-color: transparent;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    .btn{
                        padding: 10px 9px;
                        background-color: rgb(24, 135, 24);
                        width: 20%;
                    }

                    .btn:hover{
                        background-color: rgb(17, 143, 17);
                    }
                    
                    p{
                        color: #a4a6a5;
                    }
                }
            }
        }
    }
    .footerWrap{
        border-top: none;
    }
}