.Navbar {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  background-color: var(--clientHeader);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  -webkit-user-select: none;
  user-select: none;
  @media screen and (min-width: 769px) {
    .menuHorizontalWrap {
      display: none !important;
    }
  }
  .Container {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    .menuHorizontalWrap {
      width: 100%;
      background-color: white !important;
      margin-top: 100px;
      position: fixed;
      top: 0;
      .menuHorizontal {
        display: flex;
        flex-direction: column !important;
        width: 100%;
        .itemNav {
          text-decoration: none;
          position: relative;
          width: 100%;
          display: flex;
          justify-content: left;
          align-items: center;
          flex-direction: row;

          height: 70px;
          border-bottom: 1px solid #f3f3f3;
          color: #303030;
          .iconItem {
            margin-left: 1rem;
            font-size: 2rem;
          }
          .itemContent {
            font-size: 1.3rem;
            text-align: center;
            padding: 0 10px;
            line-height: 14px;
          }
        }
        .itemNav.active::after {
          text-decoration: none;
          background: #922c88;
          border-radius: 10px;
          position: absolute;
          width: 6px;
          height: 0px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        .itemNav.active {
          color: var(--adminMainColor);
        }
        .itemNav:hover {
          text-decoration: none;
          color: var(--adminMainColor);
        }
      }
    }
    .menuBrand {
      // background-color: rgb(61, 100, 218);
      width: 100px;
      height: auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      border: none;
      color: white;
      .iconLogo {
        font-size: 20px;
        color: rgba(143, 143, 143, 1);
      }
      .iconLogo.active {
        color: var(--adminMainColor);
        border: none;
      }
    }
  }
}
