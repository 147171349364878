.wrapBox {
  margin-bottom: 15px;

  .wrapItem {
    display: flex;
    flex-direction: column;
    .label {
      color: white;
    }
    .type {
      color: black;
      border-radius: 0px;
      padding: 1px 2px;
    }
  }

  .error {
    color: #ec3e13;
    font-size: 13px;
    margin: 0px 1px;
    position: absolute;
  }
}
