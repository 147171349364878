@media screen and (max-width: 680px)
{
    .footer .footer-inner .info {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .footer .footer-inner .logo {
        display: none !important;
    }
    .footer .footer-inner .info p, .footer .footer-inner .info ul li a {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 991.98px)
{
    .footer .footer-inner .info {
        padding-top: 5px!important;
    }
    .footer .footer-inner .logo {
        flex: 0 0 100px !important;
        max-width: 100px !important;
    }
    .footer-inner .logo img {
        width: 100px !important;
    }
}
.footer {
    display: block;
    overflow: hidden;
    width: 100%;
    background-color: #242424;
    padding: 10px 0;
}
.footer .footer-inner {
    margin: 0 auto;
    width: 100%;
    max-width: 940px;
    display: flex;
    align-items: center;
    padding: 20px 0;
}
.footer .footer-inner .logo {
    flex: 0 0 310px;
    max-width: 310px;
    margin-right: 10px;
    text-align: center;
}
.footer .footer-inner .info {
    flex: 1;
}
a {
    color: #b22222;
}
.footer .footer-inner .logo img {
    width: 130px;
}
.footer .footer-inner .info p.copyright {
    text-transform: uppercase;
}

.footer .footer-inner .info p {
    font-size: 16px;
    color: #727272;
    margin-bottom: 5px;
}
.footer .footer-inner .info ul li {
    display: inline-block;
    padding-right: 10px;
}
.footer .footer-inner .info ul li a {
    color: #727272;
}