.main{
    min-height:800px;   
    .bodyMain{
        border-radius: 5px;
        padding: 40px 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: white !important;
        height: 600px;
        .datePicker{
            float: right;
        }
    }
    .wrap{
        .note{
            margin-left: 20px;
            ul{
                margin-left: 20px;
                li{
                    margin-top: 5px;
                    list-style: circle;
                }
            }
        }
        .bodyDetail{
            h5{
                margin-left: 20px;
            }
            @media screen and (max-width:575px ){
                .group{
                    grid-template-columns: auto !important;
                }
            }
            @media screen and (max-width:1230px ){
                .group{
                    grid-template-columns: auto auto!important;
                }
            }
            .group{
                display: grid;
                grid-template-columns: auto auto auto auto;
                width: 100%;
                margin: 10px;
                .groupDetail{
                    font-size: 16px;
                    display: flex;
                    flex-direction: column;
                    margin: 5px;
                    gap: 10px;
                    .detail{
                        display: flex;
                        flex-direction: row;
                        align-items: start;
                        margin: 0px 20px;
                        h6{
                            margin: 0px 5px 0px 2px;
                        }
                        .type{
                            border: 4px solid black ;
                            padding: 6px 20px;
                            background-color: rgb(233, 227, 227);
                       } 
                    }
                }
            }
        }
    }
}