.main {
    .topMain {
      border-bottom: 1px solid #e4e4e4;
      .topMainTitle {
        color: black;
        font-size: 1.7rem;
        font-weight: 400;
        padding-right: 10px;
        display: inline;
      }
      .topMaincontentWrap {
        display: inline;
        .topMaincontent {
          position: relative;
  
          font-size: 0.8rem;
          color: #333333;
          opacity: 0.7;
          cursor: pointer;
        }
        .topMaincontent::after {
          margin-left: 0.6rem;
          margin-right: 0.6rem;
          content: "|";
        }
        .topMaincontent:hover {
          color: var(--adminMainColor);
        }
      }
      .topMaincontentWrap .topMaincontent:last-child:after {
        content: "";
      }
    }
    .bodyMain {
      width: 100%;
      // padding: 50px 0px;
      // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: white !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      .bodyMainTop {
        width: 90%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
        .btn {
          text-decoration: none;
          display: inline-block;
          justify-content: center;
          align-items: center;
          background-color: var(--adminMainColor);
          opacity: 0.8;
          padding: 10px 10px;
          border-radius: 10px;
          font-size: 1rem;
          opacity: 0.7;
          font-weight: bold;
          color: white;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
          transition: all 0.5s;
          -webkit-user-select: none;
          user-select: none;
          cursor: pointer;
        }
        .btn:hover {
          box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
            rgba(0, 0, 0, 0.23) 0px 3px 6px;
          opacity: 1;
        }
        .filterWrap {
          display: flex;
          align-items: center;
          .searchWrap {
            display: inline-block;
            border-radius: 10px;
            border: 1px solid gray;
            overflow: hidden;
            height: 100%;
            padding: 4px 0px;
  
            opacity: 0.5;
            .Search {
              border: none;
              padding: 2px 5px;
            }
            .Search:focus {
              outline: gray;
            }
            .contentSearch {
              // border-left: 1px  solid #333333 ;
              line-height: 26px;
              height: 100%;
              position: relative;
              display: inline-block;
              font-size: 1rem;
              color: #424242;
              padding: 0px 10px;
              opacity: 0.5;
              // opacity: 0.8;
              // background-color:  var(--adminMainColor);
            }
            .contentSearch::after {
              content: "|";
              z-index: 5;
              position: absolute;
  
              left: 0;
            }
          }
  
          .btnFilter {
            margin-left: 5px;
            border: 1px;
            padding: 0.3rem 1rem;
            font-size: 0.9rem;
            line-height: 1.5;
            color: #922c88;
            border-radius: 10px;
            border: 1px solid #922c88;
            cursor: pointer;
            position: relative;
            -webkit-user-select: none;
            user-select: none;
            .bellFilter {
              position: absolute;
              bottom: 71%;
              right: -1;
              left: 86%;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              box-shadow: rgba(192, 109, 109, 0.5) 0px -50px 36px -28px inset;
            }
            .bellFilter.active {
              background-color: #f10c0c;
            }
          }
        }
      }
      .mainWrap {
        width: 100%;
        .row {
          .btnBlock {
            display: flex;
            justify-content: space-between;
            padding: 5px 20px;
            margin-top: 20px;
            .note {
              font-size: 0.9rem;
              opacity: 0.8;
              color: var(--adminMainColor);
            }
            .btn {
              display: inline-block;
              justify-content: center;
              align-items: center;
              background-color: var(--adminMainColor);
              opacity: 0.8;
              padding: 4px 10px;
              border-radius: 10px;
              font-size: 1rem;
              opacity: 0.7;
              font-weight: bold;
              color: white;
              box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
              transition: all 0.5s;
              -webkit-user-select: none;
              user-select: none;
              cursor: pointer;
            }
            .btn:hover {
              opacity: 1;
            }
          }
          .listFileWrap {
            padding: 20px;
            position: relative;
            display: flex;
            justify-content: start;
            flex-direction: column;
            align-items: start;
  
            .Wrapss {
              display: flex;
              width: 100%;
              @media screen and (max-width:768px) {
                .WrapTitle{
                  width: 100%!important;
                  padding: 20px 0px 10px 0px !important;
                }
              }
              .WrapTitle {
                width: 50%;
                display: flex;
                justify-content: end;
                flex-direction: column;
                padding: 20px 10px 10px 0px;
  
                .titleW {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-top: 10px;
                  .input {
                    padding: 10px;
                    width: 100%;
                    border-radius: 10px;
                    border: 1px solid gray;
                  }
                  .imgForm {
                    margin: 0.6rem 0.3rem;
                    @media screen and (max-width:768px) {
                      .wrapImg{
                        margin: 0 auto!important;
                      }
                    }
                    .wrapImg {
                      min-height: 200px;
                      overflow: hidden;
                      min-width: 20%;
                      max-width: 50%;
                      border-radius: 10px;
                      border: 1px solid gray;
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      .iconImg {
                        font-size: 3rem;
                        opacity: 0.6;
                      }
                      .choseImg {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        z-index: 3;
                        opacity: 0;
                      }
                    }
                  }
                }
              }
              .WrapIMG {
                width: 50%;
                min-height: 300px;
                position: relative;
                padding: 10px 0px;
                .btnPopupWrap {
                  width: 100%;
                  .btnPopup {
                    position: absolute;
                    left: 1%;
                    background-color: #f8f8f8;
                    color: #922c88;
                    border: 1px solid #922c88;
  
                    &:hover {
                      background-color: #922c88;
                      color: #f8f8f8;
  
                      .editIcons {
                        color: #f8f8f8;
                      }
                    }
  
                    .editIcons {
                      font-size: 1.5rem;
                    }
                  }
  
                  .imgWrapInput {
                    background-color: transparent;
                    border-style: none;
                    outline: none;
                    max-width: 100px;
  
                    .property {
                      cursor: pointer;
                      position: absolute;
                      text-align: start;
                      font-size: 1rem;
                      line-height: 1.7rem;
                      width: 100%;
                      left: 10px;
                      right: 0px;
                    }
                    .inputImg {
                      cursor: pointer;
                      opacity: 0;
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
                .imgWrap {
                  margin-top: 39px;
                  border: 1px solid #922c88;
                  border-radius: 20px;
                  padding: 5px;
                  width: 100%;
                  height: 300px;
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  &:hover {
                    border: 2px solid #922c88;
                  }
                  .img {
                    padding: 10px;
                    width: auto;
                    height: 300px;
                    border-radius: 20px;
                  }
  
                  .imgIcons {
                    font-size: 10rem;
                    color: #999;
                    left: 40%;
                    top: 25%;
                  }
                }
              }
            }
  
            .cKEditor {
              width: 100%;
              min-height: 400px;
            }
            .footer {
              padding: 50px 0px;
              display: flex;
              justify-content: center;
              .footerWrap {
                .Pagination {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  .prev {
                    cursor: pointer;
  
                    padding: 10px;
                    font-size: 1.2rem;
                    font-weight: bold;
                    opacity: 0.7;
                    margin-right: 10px;
                  }
                  .prev:hover,
                  .last:hover {
                    opacity: 0.9;
                  }
                  .item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    width: 50px;
                    height: 50px;
                    border-radius: 10px;
                    font-size: 1.2rem;
                    opacity: 0.7;
                    font-weight: bold;
                    color: #424242;
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                    transition: all 0.5s;
                    cursor: pointer;
                  }
                  .item.active {
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                      rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    background-color: #504178;
                    color: white;
                  }
                  .item:hover {
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                      rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    background-color: #504178;
                    color: white;
                  }
                  .last {
                    padding: 10px;
                    font-size: 1.2rem;
                    font-weight: bold;
                    opacity: 0.7;
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  