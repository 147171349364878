@media only screen and (max-width: 480px) {
  .main {
    padding-top: 80px !important;
  }
  .download-main .tit {
    text-align: center !important;
    padding-left: 0% !important;
  }
  .download-main .step {
    height: fit-content !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .main {
    padding-top: 100px !important;
  }
  .download-main .download-option {
    flex-direction: column;
  }
  .content-main {
    margin: 0 !important;
  }
}

.main {
  background-image: url("../../../Css/img/bg-trung-thu-2023.jpg");
  width: 100%;
  background-color: #000;
  background-repeat: no-repeat;
  padding-top: 500px;
  min-height: 100vh;
}

.content-main {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 940px;
  position: relative;
}

.content-main .content-center {
  flex: 1;
  display: block;
  overflow: hidden;
  margin: 5px 0 0 0;
}

.download-main {
  overflow: hidden;
  max-width: 850px;
  margin: 0 auto;
  padding: 15px 20px 20px 20px;
  background: #000 url("../../../Css/img/bg-download.jpg") center top no-repeat;
}

.download-main .tit {
  display: block;
  height: 80px;
  line-height: 80px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding-left: 80px;
  font-size: 24px;
  font-weight: bold;
  background: #000 url("../../../Css/img/bg-head.jpg") center top no-repeat;
}

.download-main * {
  line-height: 20px;
}

.clearfix {
  clear: both;
}

.download-main .download-option {
  display: flex;
}

.download-main .step-footer {
  padding: 20px 30px;
}

.download-main .step {
  padding: 10px;
  margin-top: 6px;
  color: #fff;
  background: rgba(28, 28, 28, 0.8);
  border: 3px solid #3b2704;
}

.download-main .border-footer {
  margin-top: 15px;
  height: 40px;
  background: url("../../../Css/img/bg-footer.png") center top no-repeat;
}

.download-main .text-center {
  text-align: center;
}

.download-main .block {
  display: block;
}

.download-main .step.step-start {
  background-color: rgba(35, 12, 0, 0.8);
  border-color: #400702;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.download-main .step.step-start a {
  font-size: 24px;
  line-height: 36px;
  color: #ff7200;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid #ff7200;
}

.download-main .package.package-full .highlight {
  margin-left: 5px;
  color: #e87800;
}

@media screen and (max-width: 465px) {
  .download {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 0px !important;
  }
}

.download-main .download {
  text-align: center;
}

.download-main img {
  background: url("../../../Css/img/download_btn_1_touch.png") no-repeat;
  background-size: 70%;
  background-position: center;
}

.download-main img:hover {
  background: url("../../../Css/img/download_btn_1_touch_2.png") no-repeat;
  background-size: 70%;
  background-position: center;
}

.download-main .last-btn {
  background: url("../../../Css/img/download_btn_file_1.png") no-repeat;
  background-size: 70%;
  background-position: center;
}

.download-main .last-btn:hover {
  background: url("../../../Css/img/download_btn_file_2.png") no-repeat;
  background-size: 70%;
  background-position: center;
}

.download-main .info-news {
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}

.download-main .lic {
    color: blue
}
.download-main .info-news a {
    text-decoration: none;
    display: block;
    line-height: 26px;
}

.download-main .info-news a:before {
  content: "-";
  display: inline-block;
  margin-right: 20px;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
