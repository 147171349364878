.wrap-box {
  position: relative;
}

.wrap {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.input {
  padding: 5px 10px;
  background: #000;
  color: #d2a763;
  border-color: #564216;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
}

.error {
  color: #ec3e13;
  font-size: 13px;
  margin: 0px 1px;
  position: absolute;
}
