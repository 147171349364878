.mainWrap{
    background-color: #f8f8f8;
    .responsive_table{
        .table_header {
          
            .itemHeader {
               
               
              }
          }
          
    }
    .body_table

          {

              .tr{
               
                  .item_body{
                    
                  }
              }
          }
    .tempyBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px ;
        .iconEmty
        {
          
            color: #5f5b5b;
            font-size: 4rem;
            display: block;

        }
        .titleEmty{
            padding: 10px;
            display: block;
            color: #5f5b5b;
            font-size: 2rem;
        }
    }
}

 