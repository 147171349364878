.main {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
  width: 120px;
  height: calc(100% - 80px);
  top: calc(100px);
  background: #fff;
  z-index: 3;
  position: fixed;
  transition: transform 0.3s;
  padding-top: 2px;
  left: -120px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: all 0.5s;
  -webkit-user-select: none;
  user-select: none;
  .mainWrap {
    width: 100%;
    background: #ffffff;
    z-index: 2;
    top: 0;
    position: absolute;
    left: 0;
    border-left: 1px solid #f3f3f3;
    transition: transform 0.5s;
    height: calc(100%);
    .menuWrap {
      position: absolute;
      z-index: 5;
      .menuHorizontal {
        position: relative;
        .itemNav {
          text-decoration: none;
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: 80px;
          border-bottom: 1px solid #f3f3f3;
          color: #303030;
          .iconItem {
            font-size: 1.7rem;
          }
          .itemContent {
            font-size: 0.9rem;
            text-align: center;
            padding: 0 10px;
            line-height: 14px;
          }
        }
        .itemNav:hover {
          text-decoration: none;
          color: var(--adminMainColor);
        }
        .itemNav.active::after {
          text-decoration: none;
          content: " ";
          background: #922c88;
          border-radius: 10px;
          position: absolute;
          width: 6px;
          height: 50px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        .itemNav.active {
          color: var(--adminMainColor);
        }
      }
    }
  }
}
.main.active {
  left: 0;
}
