h1 {
    color: white;
    text-align: center;
    margin-top: 20%;
}
.messenger {
    padding: none;
    margin: none;
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    cursor: pointer;
    background-color: white;
    border-radius: 45%;
    box-shadow: 0px 1rem 1rem rgba(79, 78, 78, 0.5);
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: slideInFromBottom 1s ease-out forwards;
    animation-delay: 2s;
    z-index: 10;

    @media screen and (max-width:480px) {
        bottom: 2rem ;
        right: 1rem ;
    }
}

#messenger {
    width: 3rem;
    height: 3rem;
    /* @media screen and (max-width:480px) {
            padding: 2px;
            width: 4rem ;
            height:4rem ;
            margin-left: auto ;
            margin-right: auto ;
    } */
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}