*{
    box-sizing: border-box !important;
    padding:0;
    margin: 0;
 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    
    outline: none;
    
}

h3 {
  font-size: 28px;
  line-height: 20px !important;
}
.col-12{padding: 0px}
a{
    text-decoration: none;
}
html{
  font-size: 15px;
}
h1{
  font-size: 3rem;

}
h2{
  font-size: 2rem;
}
@media (max-width:700px) {
  html{
    font-size: 14px;
  }
}
/* @media (max-width:612px) {
  html{
    font-size: 10px;
  }
} */
ul {
  padding: 0px;
    list-style: none;
    }
:root{
    --clientHeader:#ffffff;
    --clientFooter:#E5E5E5;


    --adminHeader:#E5E5E5;
    --adminFooter:#E5E5E5;
    --adminMainColor:#922c88;

    --carouselHomeClient:450px;

    --clientMaincolor:#039700;
    --colorBtn:white,

    --shadowCard:rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


#root{
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.carousel-inner{
    max-height:var( --carouselHomeClient);
    
}
.carousel-control-prev ,.carousel-control-next{
    display: none;
}
.flex-grow-1{
    background-color: #f7f7f7;
}
.fullscreen.fullscreen-enabled{
    background-color: #ffffff;
    overflow: scroll;
}

.scrollbar {
    margin-left: 22px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
  }
  
  .force-overflow {
    min-height: 450px;
  }
  
  #wrapper {
    text-align: center;
    margin: auto;
  }
  
  #style-1::-webkit-scrollbar,
  #style-2::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }
  
  #style-4::-webkit-scrollbar,
  #style-5::-webkit-scrollbar,
  #style-6::-webkit-scrollbar,
  #style-7::-webkit-scrollbar,
  #style-8::-webkit-scrollbar,
  #style-9::-webkit-scrollbar,
  #style-10::-webkit-scrollbar,
  #style-11::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }
  
  /**  STYLE 1 */
  #style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(218, 218, 218, 0);
    background-color: rgb(199, 199, 199,0.2);
  }
  
  #style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0);
    border-radius: 10px;
    background-color: rgb(245, 245, 245,0);
  }
  
  /**  STYLE 2 */
  #style-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #D62929;
  }
  
  #style-2::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  /**  STYLE 3 */
  #style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(248, 248, 248, 0);
    background-color: #F5F5F5;
  }
  
  #style-3::-webkit-scrollbar {
    height: 0px;
    width: 6px;
    background-color: rgba(245, 245, 245,0.2);
  }

  #style-3::-webkit-scrollbar:hover{
    background-color: rgba(245, 245, 245,0.5);
  }
  
  #style-3::-webkit-scrollbar-thumb {
    background-color: rgba(105, 105, 105,0.2);
  }

  #style-3::-webkit-scrollbar-thumb:hover{

  }
  
  /**  STYLE 4 */
  #style-4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  #style-4::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
  }
  
  /**  STYLE 5 */
  #style-5::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  #style-5::-webkit-scrollbar-thumb {
    background-color: #0ae;
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                      color-stop(.5, rgba(255, 255, 255, .2)),
                      color-stop(.5, transparent), to(transparent));
  }
  
  /**  STYLE 6 */
  #style-6::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  #style-6::-webkit-scrollbar-thumb {
    background-color: #F90; 
    background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%,
                          transparent 25%,
                          transparent 50%,
                          rgba(255, 255, 255, .2) 50%,
                          rgba(255, 255, 255, .2) 75%,
                          transparent 75%,
                          transparent)
  }
  
  /** STYLE 7 */
  #style-7::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
  }
  
  #style-7::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
                       left bottom,
                       left top,
                       color-stop(0.44, rgb(122,153,217)),
                       color-stop(0.72, rgb(73,125,189)),
                       color-stop(0.86, rgb(28,58,148)));
  }
  
  /**  STYLE 8 */
  #style-8::-webkit-scrollbar-track {
    border: 1px solid black;
    background-color: #F5F5F5;
  }
  
  #style-8::-webkit-scrollbar-thumb {
    background-color: #000000;  
  }
  
  /**  STYLE 9 */
  #style-9::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  #style-9::-webkit-scrollbar-thumb {
    background-color: #F90; 
    background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, .2) 25%,
                          transparent 25%,
                          transparent 50%,
                          rgba(255, 255, 255, .2) 50%,
                          rgba(255, 255, 255, .2) 75%,
                          transparent 75%,
                          transparent)
  }
  
  /**  STYLE 10 */
  #style-10::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(left, #96A6BF, #63738C);
    box-shadow: inset 0 0 1px 1px #5C6670;
  }
  
  #style-10::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eee;
    box-shadow: 0 0 1px 1px #bbb, inset 0 0 7px rgba(0,0,0,0.3)
  }
  
  #style-10::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(left, #8391A6, #536175);
  }
  
  /**  STYLE 11 */
  #style-11::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
    border: 1px solid #ccc;
  }
  
  #style-11::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(left, #fff, #e4e4e4);
    border: 1px solid #aaa;
  }
  
  #style-11::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  
  #style-11::-webkit-scrollbar-thumb:active {
    background: linear-gradient(left, #22ADD4, #1E98BA);
  }
  .rs-anim-fade.rs-picker-daterange-menu.rs-picker-menu{
    z-index: 10000;
  }

  #react-select-13-listbox{
    background-color: white !important;
  }
  .css-13cymwt-control{
    border: 1px solid rgba(0, 0, 0,0.4) !important;
  }
  .css-1nmdiq5-menu{
    background-color: white !important;
  }
  .pdf-container {
    width: 300px;
    height: 400px;
    overflow: scroll;
  }
  .ck-rounded-corners{
    width: 100%;
  }
  .ck.ck-editor {
    width: 100%;
  }
  .ck.ck-editor__main{
    min-height:  700px !important;
    width: 100%;
  } 
  .ck-blurred.ck-editor__editable_inline
  {
    min-height:  700px !important;
    width: 100%;
  }
  .ck-focused{
    min-height:  700px !important;
    width: 100%;
  }
  .ck-editor.ck-rounded-corners{
    width: 100% !important;
  }
  figure.image{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  figure.image img{
    margin-left: auto;
    margin-right: auto;
  }


  /* a */
  

  .__web-inspector-hide-shortcut__, .__web-inspector-hide-shortcut__ *, .__web-inspector-hidebefore-shortcut__::before, .__web-inspector-hideafter-shortcut__::after {
    visibility: visible !important;
}

.carousel-indicators{
  display: flex;
  justify-content: right;
  padding: 5px -10px;
  margin: 5px !important;
}
.carousel-indicators button{
  /* border: none !important; */
  position:relative !important;
  width: 20px !important;
  
}

.carousel-indicators button::before{
  left: 0;
  top: 0;
  
  transform: translateY(-50%);
  position: absolute;
  z-index: 100000;
  content: '';
  display: inline-block;
  width: 16px;
  height: 18px;
  background: url("./img/dot-slide.png") no-repeat;
}
.carousel-indicators button.active::before{

  background-position: -16px 0;
}
#style-1::-webkit-scrollbar,
#style-2::-webkit-scrollbar {
  /* display: none; */
  opacity: 0;
  width: 5px;
  background-color: transparent;
  /* background-color: #090909; */
}


#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 1px rgba(152, 48, 48, 0.2);
  background-color: #3f4d5a;
  /* background-color: #3f4d5a; */
}

#style-1:hover::-webkit-scrollbar-thumb{
  background-color: #3f4d5a;
}

#style-2:hover::-webkit-scrollbar-thumb{
  background-color: rgba(173, 173, 173,0.6);
}

#style-1::-webkit-scrollbar-track {
  display: none;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.7);
  border-radius: 10px;
  opacity: 0;
  background-color: var(--admin-bg-color1);
}

.grecaptcha-badge{
  display: none;
}