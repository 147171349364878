@media only screen and (max-width: 480px){
  .news .nav ul {
    flex: none !important;
    display: inline-block;
  }

  .news .list-news{
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
  }
  .news .list-news .item.hot {
    float: left;
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .news .list-news .list {
    float: right;
    width: 100% !important;
    max-height: 300px;
  }
  .news .list-news .item.hot .cover {
    max-width: 90% !important;
    margin: 10px;
    float: unset;
    max-height:none !important;
  }

  .news .list-news .item.hot .viewmore {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 767.98px) {
  .news .list-news .item.hot .cover {
    max-width: 100%;
  }
  .news .list-news .item.hot {
    margin-bottom: 10px !important;
    width: 100% !important;
  }
  .news .list-news .item.hot .cover {
    max-width: 100% !important;
    padding-right: 10px;
    max-height:none !important;
}
.news .list-news .list {
    float: none;
    width: 100% !important;
    height: 290px;
    max-height: unset;
}
.news .nav ul {
  flex: none !important;
  display: inline-block;
}
.news .list-news{
  display: flex !important;
  flex-direction: column !important;
  height: auto !important;
}
}
.news {
  margin-top: 4px;
  display: block;
  overflow: hidden;
  border: 1px solid #070502;
  background: url("../../../Css/img/bg-news.jpg") repeat;
}
.news .nav {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 38px;
}
.news .nav ul {
  flex: 1;
  display: inline-block;
}
.news .nav ul li {
  display: inline-block;
  position: relative;
}
.news .nav ul li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: url("../../../Css/img/bg-line-tab.png") no-repeat;
  width: 12px;
  height: 45px;
}

.news .nav ul li a {
  display: block;
  overflow: hidden;
  text-align: center;
  line-height: 38px;
  height: 38px;
  text-decoration: none;
  color: #fffcb7;
  font-size: 14px;
  padding-right: 12px;
  padding: 0 20px 0 10px;
}
.news .nav form {
  flex: 1;
}
.news .nav form input {
  background: transparent;
  border: 0px;
  height: 38px;
  line-height: 38px;
  padding: 0 10px;
  font-size: 16px;
  color: #565656;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
}
.news .nav form button {
  border: 0px;
  background: transparent;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  cursor: pointer;
  width: 38px;
  height: 38px;
  line-height: 38px;
  font-size: 30px;
  text-align: left;
  text-indent: -9999px;
  background: url("../../../Css/img/icon-search.png") center center no-repeat;
}
.news .nav:after {
  content: "";
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: rgba(66, 61, 44, 1);
  background: -moz-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(66, 61, 44, 1)),
    color-stop(55%, rgba(110, 103, 62, 1)),
    color-stop(100%, rgba(66, 61, 44, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: -ms-linear-gradient(
    left,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(66, 61, 44, 1) 0%,
    rgba(110, 103, 62, 1) 55%,
    rgba(66, 61, 44, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#423d2c', endColorstr='#423d2c', GradientType=1 );
}
.news .list-news {
  display: block;
  overflow: hidden;
  padding: 30px 0px 10px 12px;
  /* height: 430px; */
}
.news .list-news .item.hot {
  float: left;
  width: 48%;
  margin-bottom: unset;
}
.news .list-news .item {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-bottom: 14px;
}
.news .list-news .item.hot .cover {
  max-width: 287px;
  margin-bottom: 10px;
  float: unset;
  margin-right: unset;
  max-height: 200px;
}
.news .list-news .item .cover {
  max-width: 114px;
  float: left;
  margin-right: 5px;
}
a {
  color: #b22222;
}
.news .list-news .item .cover img {
  width: 100%;
}
.news .list-news .item.hot .detail {
  width: 100%;
}
.news .list-news .item .detail {
  float: left;
  width: calc(100% - 119px);
}
.news .list-news .item .detail a {
  text-decoration: none;
}
.news .list-news .item.hot p.desc {
  height: 100px;
  display:-webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 10px;
}

.news .list-news .item.hot .desc {
  display: block;
}
.news .list-news .item .desc {
  font-size: 12px;
  color: #fff3d4;
  display: none;
}
.news .list-news .item.hot h3 {
  color: #ffe58b;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.news .list-news .item h3 {
  font-size: 12px;
  font-weight: 700;
  color: #ffe58b;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.news .list-news .item.hot .viewmore {
  display: block;
}
.news .list-news .item .detail a {
  text-decoration: none;
}
.news .list-news .item .viewmore {
  font-size: 12px;
  color: #ffe58b;
  text-transform: uppercase;
  text-decoration: none;
  display: none;
}

.news .list-news .list {
  float: right;
  width: 50%;
  max-height: 320px;
}
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.mCustomScrollBox {
  position: relative;
  overflow: auto;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}
.mCustomScrollbar
  > .mCustomScrollBox
  > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar
  > .mCustomScrollBox
  ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}
.news .nav ul li a:hover {
  color: #fff;
}
