.wrap {
  margin-bottom: 40px;
  position: relative;

  .inputBox {
    position: relative;
    margin: 30px 0px 3px 0px;
    width: 310px;
    border-bottom: 2px solid #fff;
    display: flex;
    flex-direction: column;

    .label {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 1em;
      pointer-events: none;
      transition: 0.5s;
    }

    .input {
      width: 100%;
      height: 50px;
      background: transparent;
      outline: none;
      font-size: 1em;
      border: none;
      padding: 0 35px 0 5px;
      color: #fff;
      border-radius: 10px;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      top: -5px;
    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin: 0px 5px;
    position: absolute;
  }
}
