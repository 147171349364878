@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'poppins',sans-serif;
}

.main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    height: 500px;
    background-position: center;
    background: url('../../../Css/img/backGround4.jpg')no-repeat;
    background-size: cover;
    .formBox{
        position: relative;
        width: 400px;
        height: 500px;
        background: transparent;
        border: 2px solid rgba(255,255,255,0.5);
        border-radius: 20px;
        backdrop-filter: blur(15px);
        display: flex;
        justify-content: center;
        align-items: center;
        .formValue{
            
            .form{
                h2{
                    font-size: 2em;
                    color: #fff;
                    text-align: center;
                }
                .inputbox{
                    position: relative;
                    margin: 20px 0;
                    width: 310px;
                    border-bottom: 2px solid #fff;
                    .valueErr{
                        color: #f44336;
                        margin-bottom: 5px;
                    }
                    label{
                        position: absolute;
                        top: 50%;
                        left: 5px;
                        transform: translateY(-50%);
                        color: #fff;
                        font-size: 1em;
                        pointer-events: none;
                        transition: .5s;
                    }
                    .input:focus ~ .label{
                        top: -5px;
                    }
                   
                    .input:valid ~ .label{
                        top: -5px;
                    }
                    .input {
                        width: 100%;
                        height: 50px;
                        background: transparent;
                        outline: none;
                        font-size: 1em;
                        border: none;
                        padding:0 35px 0 5px;
                        color: #fff;
                        // border:1px solid #575757;
                        border-radius: 10px;
                    }
                    .icon{
                        position: absolute;
                        right: 8px;
                        color: #fff;
                        top: 20px;
                    }
                }
                .forget{
                    margin: -15px 0 15px ;
                    font-size: .9em;
                    color: #fff;
                    display: flex;
                    justify-content: space-between;  
                    label {
                        input{
                            margin-right: 3px;
                        
                        }
                        a{
                            color: #fff;
                            text-decoration: none;
                        }
                        a:hover{
                            text-decoration: underline;
                        }
                    }
                }
                .span{
                    margin-top: 10px;
                    display: block;
                    width: 100%;
                    height: 40px;
                    border-radius: 40px;
                    background: #fff;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    font-size: 1em;
                    font-weight: 600;
                    text-align: center;
                    line-height: 40px;
                }
                .register{
                    font-size: .9em;
                    color: #fff;
                    text-align: center;
                    margin: 25px 0 10px; 
                    p {
                            a{
                            text-decoration: none;
                            color: #fff;
                            font-weight: 600;
                        }
                        a:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}


