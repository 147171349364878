.mainWrap {
  .responsive_table {
    .table_header {
      .itemHeader {
        min-width: 12rem !important;
      }
    }
  }
  .body_table {
    .tr {
      .item_body {
      }
    }
  }
  .emptyBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    .iconEmpty {
      color: #5f5b5b;
      font-size: 4rem;
      display: block;
    }
    .titleEmpty {
      padding: 10px;
      display: block;
      color: #5f5b5b;
      font-size: 2rem;
    }
  }
}
