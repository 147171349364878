.main {
  min-height: 800px;

  .bodyMain {
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white !important;

    .bodyMainTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;

      @media screen and (max-width: 560px) {
        .filterWrap {
          flex-direction: column !important;
          .searchWrap {
            width: 100%;
            margin-bottom: 10px;
            .search {
              width: 75%;
            }
          }
          .filterBody {
            width: 100%;
            .btnCreate {
              text-align: center;
              width: 100%;
            }
            .typeWrap {
              width: 100% !important;
            }
          }
        }
      }

      .filterWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .searchWrap {
          display: inline-block;
          border-radius: 10px;
          border: 1px solid gray;
          overflow: hidden;
          height: 100%;
          padding: 4px 0px;

          opacity: 0.5;
          .search {
            border: none;
            padding: 2px 5px;
          }
          .Search:focus {
            outline: gray;
          }
          .btnSearch {
            line-height: 26px;
            height: 100%;
            position: relative;
            display: inline-block;
            font-size: 1rem;
            color: #424242;
            padding: 0px 10px;
            opacity: 0.5;
          }
          .btnSearch::after {
            z-index: 5;
            position: absolute;
            left: 0;
          }
        }
        .filterBody {
          display: flex;
          flex-direction: row;
          gap: 20px;
          .btnCreate {
            background-color: green;
            border-radius: 10px;
            padding: 10px;
            cursor: pointer;
            .label {
              color: white;
            }
          }
          .btnCreate:hover {
            opacity: 0.8;
          }
          .typeWrap {
            width: 130px;
          }
        }
      }
    }
    .table {
      width: 100%;
      max-height: 400px;
      overflow: auto;
    }
  }
  .footer {
    padding: 10px 0px;
    display: flex;
    justify-content: end;
    .footerWrap {
      -webkit-user-select: none;
      user-select: none;
    }
  }
}
