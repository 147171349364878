.mainCarousel{
    -webkit-user-select: none;
    user-select: none;
    visibility:visible !important;
    .carouselItem{
        position: relative;
        .carouselimg{
           height: 400px;
           @media screen and (max-width:480px){
                height: 250px !important;
           }
        }
        .caption{
            top: 10%;
            left: 10%;
            right: 10%;
            height: var(--carouselHomeClient);
            display: flex;
            align-items: start;
            justify-content: start;
            flex-direction: column;
            z-index: 100;
           
        
           .titelCarousel{
                font-size: 3rem !important;
                color: #F25B06 !important;
           }
           .contentCarousel{
            
            text-align: start;
            max-width: 40%;
            font-size: 1.5rem;
            white-space: wrap;
            text-shadow: 0px 0px 3px #000000;
           }
           .btnCarousel{
            
            font-size: 1.2rem;
            color: var(--colorBtn);
            border: 0px;
            padding: 10px 40px;
            border-radius: 20px;
            background-color:var(--clientMaincolor) ;
           
            transition: all 6s;
           }
        }
    }
    
}