.main {
  .headerWrap {
    background-color: #575757;
    color: #ffffff;
    border: 2px solid white;
    border-bottom: none;
    padding: 10px 20px 5px 20px;
  }

  .bodyWrap {
    padding: 2px;
    background-color: #575757;
    border: 2px solid white;
    border-top: none;
    border-bottom: none;
  }
  .footerWrap {
    border: 2px solid white;
    border-top: none;
    background-color: #575757;
    padding: 5px 20px;
  }
}
