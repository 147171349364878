.outlet{
    margin-top: 80px;
    padding: 20px 0px;
    background-color: #f8f8f8;
    transition: all .5s;
    width: calc(100% );
    display: flex;
    justify-content: center;
   
    .outletWrap{
      
        width: 93%;
       
    }
}
.outlet.active{
    transform: translateX( 120px);
    width: calc(100% - 120px);
}
@media screen and (max-width: 768px) {
    .outlet.active{
        transform: none;
        width: 100%;
    }
}