.wrapBox {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  .label {
    color: white;
  }
  .dateTime {
    background-color: white;
  }
}
