@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "poppins", sans-serif;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  height: 500px;
  background-position: center !important;
  background: url("../Css/img/backGround4.jpg") no-repeat;
  background-size: cover;
  .formBox {
    margin: 0px 5px;
    position: relative;
    width: 400px;
    height: 500px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    .formValue {
      width: 80%;
      h2 {
        font-size: 2em;
        color: #fff;
        text-align: center;
      }
      .voucherText {
        color: rgb(254, 253, 254);
      }
      .form {
        .span {
          display: block;
          width: 100%;
          height: 40px;
          border-radius: 40px;
          background: #fff;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 1em;
          font-weight: 600;
          text-align: center;
          line-height: 40px;
        }
        .register {
          font-size: 0.9em;
          color: #fff;
          text-align: center;
          margin: 25px 0 10px;
          p {
            a {
              text-decoration: none;
              color: #fff;
              font-weight: 600;
            }
            a:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
