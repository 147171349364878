@media screen and (max-width: 480px) {
    .wrapper.home {
        background-size: 220% 15% !important;
        background-color: rgba(26,22,11,255)!important;
        padding-top: 100px !important;
        min-height: 0 !important;
        background-position: 100% -2% !important;
    }
}
.wrapper.home {
    /* background: url(../imgs/bg-home.png?v=1.1) no-repeat center 0; */
    /* background: url(../imgs/bg-skin-noel-2020.jpg?v=1.1) no-repeat center 0; */
    background-color: #000;
    padding-top: 400px;
    background: url('../../../Css/img/header-sub.jpg') no-repeat center 0;
    /* background-attachment: fixed; */
    background-size: 100%;
    background-color: rgba(25,21,10,255);
    min-height: 100vh;
}

.wrapper {
    display: block;
    overflow: hidden;
    width: 100%;
    margin: auto;
}
.wrapper-inner {
    display: block;
    width: 100%;
    margin: 0 auto;
}
.wrapper .content-main {
    display: flex;
    margin: 0 auto 15px;
    width: 100%;
    max-width: 940px;
    position: relative;
}
.wrapper .content-main .content-center {
    flex: 1;
    display: block;
    overflow: hidden;
    margin: 5px 0 0 0;
}

 .detail-content {
    width: 100% !important;
    border: 1px solid #000;
    overflow: hidden;
    background: url('../../../Css/img/bg-heading.jpg') repeat;
   
}
.wrapper .content-main .content-center .detail-content .head {
    display: block;
    overflow: hidden;
    width: 100%;
    background: url('../../../Css/img/bg-head-detail.jpg') repeat right top;
    position: relative;
    padding-bottom: 10px;
}
.wrapper .content-main .content-center .detail-content .head:before {
    content: '';
    display: block;
    overflow: hidden;
    width: 75px;
    height: 75px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    background: url('../../../Css/img/emblem.png' ) no-repeat;
}
.wrapper .content-main .content-center .detail-content .head h3 {
    color: #ffe184;
    font-size: 24px;
    font-weight: bold;
    margin-top: 14px;
    padding-left: 100px;
}
.wrapper .content-main .content-center .detail-content .head .time {
    color: #fff;
    font-size: 14px;
    padding-left: 100px;
}
.body{
    /* margin-top: 50px; */
    padding: 10px;
}
.img{
    width: 100%;
}
figure.image img{
    margin-left: auto;
    margin-right: auto;
  }

.time {
    color: #fff;
    font-size: 14px;
    padding-left: 100px;
}

.container-iframe {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .new-detail-iframe  {
    border: 1px solid #1a1a19 ;
    margin: 0px auto 10px auto;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    width: 96%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }
  
  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }