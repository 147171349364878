.top{
    background-image: url('../../../Css/img/bg-sub.jpg');
    background-repeat: repeat;

    height: 200px;
    width: 100%;
}
@media (max-width:612px) {
    .Body0{
        height: 500px !important;
        background-position: center !important;
        background-size: 120% 100% !important;
    }
  }
.Body0{
    height: auto;
    height: 600px;
    background-image: url('../../../Css/img/mainnews.png');
  
    background-repeat: no-repeat;
    background-position: left;
    background-size: 100% 100%;
    width: 100%;
    display: flex;
    align-items: end;
    padding: 10px 0px 0px 0px;
    position: relative;
    .btgRe{
        background-image: url('../../../Css/img/bgNew.PNG');
        background-repeat: no-repeat;
        position: absolute;
        background-size: cover;
        height: 700px;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
       
    }
    .imgListNews{
        position: absolute;
        
    }
    .Body0Wrap{
        width: 100%;
        margin: 0px;
        height: 85%;
        justify-content: center;
        .contentWrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                opacity: 0.8;
            }
            .bd0TopContent{
                font-family: 'Courier New', Courier, monospace;
                transform: rotate(-90deg);
                font-size: 1rem;
                font-weight: 600;
                display: inline-block;
                color: #082f34;
                margin-right: 80%;
                white-space:nowrap;
            }
        }

            .mainWrap{
                height: 100%;
                display: flex;
                justify-content:  space-between;
                flex-direction: column;
                align-items: center;
                width: 80%;
                padding: 10px;
                padding-bottom: 0px;
                
                .TitleNewsWrap{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .bftitleNews{
                        filter: brightness(1.4);
                        opacity: 0.9;
                        width: 80%;
                    }
                    @media (max-width:612px) {
                        .bftitleNews{
                            width: 100% !important;
                        }
                      }
                    .titleNews{
                        padding: 10px;
                        position: absolute;
                        font-size: 1.5rem;
                        color: rgb(255, 255, 255,0.7);
                    }
                }
                @media (max-width:612px) {
                    .wrap{
                        max-height: 300px !important ;
                    }
                  }
                .wrap{

                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    overflow-y:scroll ;
                    max-height: 350px ;
                    .cardWrap{
                        width: 60%;
                        padding: 0px;
                        cursor: pointer;
                        .WrapIn{
                            
                            padding: 20px 20px 0px 10px;
                            position: relative;
                            width: 100%;
                            border-bottom: 1px solid rgb(255, 255, 255,0.6);
                            .imgWrap{
                                position: absolute;
                                width: 30%;
                                .img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .contenWrap{
                                display:  flex;
                                justify-content: space-between;
                                .content{
                                    font-size: 1rem;
                                    text-transform: capitalize;
                                    font-weight: 600;
                                    line-height: 50px;
                                    color: #faebd9;
                                }
                                .see{
                                    font-size: 1rem;
                                    text-transform: capitalize;
                                    font-weight: 600;
                                    line-height: 50px;
                                    color: #faebd9;
                                }
                            }
                        }
                        .WrapIn:hover{
                            .contenWrap{
                                .content{
                                    opacity: 1;
                                   
                                }
                            }
                        }
                       
                    }
                }
            }

        .Seemore{
            text-decoration: none;
            width: 60%;
            text-align: end;
            // line-height: 50px;
            color: #faebd9;
            cursor: pointer;
            .arrowIcon{
                font-size: 1.2rem;
            }
            span{
                font-size: 1.2rem;
                margin: 0px 8px;
            }
        }
    }
}

