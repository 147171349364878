.container {
  padding: 0px 20px;

  .limitWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .limit {
      width: 100%;
    }
    .rate {
      width: 100%;
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
      color: rgb(245, 220, 219);
    }

    .button {
      float: right;
    }
  }
}
