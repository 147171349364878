
.Navbar{
    // background-color: var(--clientHeader) ;
    // background-image: url('../../Css/img/nav-bg.png');
    background-color: transparent !important;
    width: 100%;
    height: 80px;
    // backdrop-filter: blur(50px);
    position: absolute;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    z-index: 10;

    @media (max-width:612px) {
        
        .wrapForgetPass ,.wrapLogin{
            margin-left: 5px;
            height: 45px !important;
            width: 90px !important;
            img{
                opacity: 0.9;
            }
        }
        .wrapLogin{

        }
        .btn{
            margin-right: 5px !important;
        }
        .BrandWrap{
            img{
                height: 80px !important;
            }
        }
    }
    .menuWrap{
        
        position: absolute;
       
        display: none;
        flex-direction: row;
        .wrapForgetPass{
            position: relative;
            border-radius: 10px;
            height: 50px;
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass{
                border-radius: 10px;
                height: 100%;
                width: 100%;
                filter: brightness(1.3);
               
            }
            .textForgetPass{
                text-align: center;
                font-size: 0.8rem;
                position: absolute;
                font-weight: bold;
                color: white;
            }
            .textForgetPass:hover .btnForgetPass{
                filter: brightness(1);
            }
            .btnForgetPass:hover ,.textForgetPass:hover{
                filter: brightness(1);
            }
        }
        
        .wrapLogin{
            margin-left: 5px;
            border-radius: 10px;
           position: relative;
           height: 50px;
           width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .btnForgetPass{
                border-radius: 10px;
                height: 100%;
                
                width: 100%;
                filter: brightness(1.3);
               
            }
            .textForgetPass{
                text-align: center;
                font-size: 0.8rem;
                position: absolute;
                font-weight: bold;
                color: white;
            }
            .textForgetPass:hover .btnForgetPass{
                filter: brightness(1);
            }
            .btnForgetPass:hover ,.textForgetPass:hover{
                filter: brightness(1);
            }
        }
    }
    
    // background-color: #88aea9;
  
    @media (max-width:986px) {
        .menuWrap{
            display: flex;
        }
        .container{
          
            width: 100% !important;
            margin: 0px;
            max-width: 100% !important;
            padding-left: 20px;
            padding-right: 20px;
            padding:0px 50px;
         
            .menuHorizontalWrap{
                    width: 100%;
                    background-image: url('../../Css/img/nav-bg.png');
                    
                    margin-top: 60px;
                    position: fixed;
                    top: 0;
                .menuHorizontal{
                    width: 100%;
                    .itemNav{
                        width: 100%;
                    }
                }
            }
        }
    }

    .container{
       width: 90%;
        padding: 0px 0px;
        
        .brand{
            
            z-index: 100;
            .BrandWrap{

                .logoImg{
                    margin-top: 60px;
                    left: 350px;
                    transform: translateX(-50%);
                    position: absolute;
                    width: auto;
                    z-index: 1000;
                    animation: animationName 3s ease infinite;
                    // filter: brightness(0.9);
                    height:150px;
                }
                
                @keyframes animationName {
                    0% {
                      filter: drop-shadow(1px 2px 1px #6a6a2d);
                    }
                    50% {
                      filter: drop-shadow(5px 10px 10px #6a6a2d);
                    }
                    100% {
                      filter: drop-shadow(1px 2px 1px #6a6a2d);
                    }
                  }
            }
        }
         
    
        .btn{
            // color: aliceblue;
            border: 1px solid #c8cbcb;
            z-index: 10;
            outline: none;
            position: fixed;
            top: 0;
            right: 0;
            margin-top: 20px;
            background-color: #535356;
            span{
                // color: #f2f7f7;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
            }
        }
        .btn:focus{
            color: transparent
        }
      
        .menuHorizontalWrap{
            // flex-basis: 0%!important;
           // display: flex !important;
            justify-content: center;
            .menuHorizontal{
                margin: 0px !important;
                display: flex;
                justify-content: end;
                .itemNav::before{
                    content: '';
                    display: inline-block;
                    overflow: hidden;
                    width: 8px;
                    height: 10px;
                    background: url('../../Css/img/dot.png') no-repeat;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    filter: brightness(0.5);
                    
                }
                .itemNav:last-child::after{
                    content: '';
                    display: inline-block;
                    overflow: hidden;
                    width: 8px;
                    height: 10px;
                    background: url('../../Css/img/dot.png') no-repeat;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    filter: brightness(0.5);
                }
                .itemNav{
                    // font-family: 'Open Sans', sans-serif;
                    font-family: 'Playfair Display', serif;
                    padding-left: 20px;
                    padding-right: 20px;
                    word-wrap:normal;
                    color: #ffde00 ; 
                    font-weight: bold;
                    box-sizing:initial !important;
                    display: flex;
                    white-space: nowrap;
                    font-size: 1.3rem;
                    line-height: 80px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                   
                    // .itemHover::after{
                    //     background-image: url('../../Css/img/line-nav.png');
                    //     content: '';
                    //     position: absolute;
                    //     box-shadow: rgba(251, 249, 225, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.8) 0px 18px 36px -18px inset;
                    //     border-radius: 50%;
                    //     opacity: 0.5;
                    //    top: 15px;
                    //    left: -1%;
                    //    bottom: 15px;
                    //    width: 3px;
                    //    background-color: rgb(251, 249, 225);
                    // }
                    
                }
                .itemNav.active{
                    color: rgb(176, 170, 134) ; 
                }
                .itemNav.active::after{
                    opacity: 1;
                    position: absolute;
                    display: inline;
                    content: '';
                    width: calc(100% - 40px);
                    height: 3px;
                    background-color: var(--clientMaincolor);
                    transform: translateY(35px); 
                    transition: all 1s;
                }
                .itemNav.active:hover:after{
                    opacity: 0;
                }
    
              
                .itemNav:hover {
                    color: #c2c2be;
                    text-decoration: none;
                    filter: drop-shadow(1px 4px 1px #6a6a2d);
                }
               
               
            }
        }
    }
}