.main{

    min-height: 800px;
    .topMain{
        -webkit-user-select: none;
        user-select: none;
        border-bottom: 1px solid #e4e4e4;
        padding-left: 0px;
        .topMainTitle{
            color: black;
            font-size: 1.7rem;
            font-weight: 400;
            padding-right: 10px;
            display: inline;
            
        }
        .topMaincontentWrap{
         
                display: inline;
                .topMaincontent{
                    
                position: relative;
                    
                font-size: 0.8rem;
                color: #333333;
                opacity: 0.7;
                cursor: pointer;
                .bellRQ{
                    background-color: red;
                    color: #ffff;
                    bottom: 0.4rem;
                    right: 10;
                    z-index: 3;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 16px;
                }
            }
            .topMaincontent::after{
                margin-left:1rem ;
                margin-right:1rem ;
                content: "|";
               
            }
            .topMaincontent:hover{
                color: var(--adminMainColor);
                
            }
        }
        .topMaincontentWrap .topMaincontent:last-child:after {
                content: "";
               
        }
       
      
    }
    .bodyMain{
        border-radius: 5px;
        padding: 10px 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: white !important;
      
        .bodyMainTop
        {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:  10px 0px;
            .btn{
                display: inline-block;
                justify-content: center;
                align-items: center;
                background-color:  var(--adminMainColor) ;
                opacity: 0.8;
                padding:4px 10px;
                border-radius: 10px;
                font-size: 1rem;
                opacity: 0.7;
                font-weight: bold;
                color: white;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                transition: all 0.5s;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
            }
            .btn:hover{
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                opacity: 1;
               
            }

            @media screen and (max-width:560px) {
                .filterWrap{
                   flex-direction: column !important;
                   .searchWrap{
                    width: 100%;
                    margin-bottom: 10px;
                    .Search{
                        width: 75%;
                    }
                   }
                   .updateProfile{
                    text-align: center;
                    width: 100%;
                   }
                }   
            }

            .filterWrap{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .searchWrap{
                    display: inline-block;
                    border-radius: 10px;
                    border: 1px solid gray;
                    overflow: hidden;
                    height: 100%;
                    padding: 4px 0px;
                    
                    opacity: 0.5;
                    .Search{
                        border: none;
                        padding:2px 5px;
                    }
                    .Search:focus{
                        outline: gray;
                    }
                    .contentSearch{
                        // border-left: 1px  solid #333333 ;
                        line-height: 26px;
                        height: 100%;
                        position: relative;
                        display: inline-block;
                        font-size: 1rem;
                        color: #424242;
                        padding: 0px 10px;
                        opacity: 0.5;
                        // opacity: 0.8;
                        // background-color:  var(--adminMainColor);
                    }
                    .contentSearch::after{
                        content: '|';
                        z-index: 5;
                        position: absolute;
                       
                        left: 0;
                    }
                }

                .updateProfile{
                    background-color: green;
                    border-radius: 10px;
                    padding: 10px;
                    cursor: pointer;
                    .profile{
                        color: white;

                    }
                }
                .updateProfile:hover{
                    opacity: 0.8;
                }
               
                .btnFilter{
                    margin-left: 5px;
                    border: 1px;
                    padding: 0.3rem 1rem;
                    font-size: 0.9rem;
                    line-height: 1.5;
                    color: var(--adminMainColor);
                    border-radius: 10px;
                    border: 1px solid var(--adminMainColor);
                    cursor: pointer;
                    position: relative;
                    -webkit-user-select: none;
                    user-select: none;
                    .bellFilter{
                        position: absolute;
                        bottom: 71%;
                        right: -1;
                        left: 86%;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%; box-shadow: rgba(192, 109, 109, 0.5) 0px -50px 36px -28px inset;
                        
                    }
                    .bellFilter.active{
                        background-color: #f10c0c;
                    }
                }
                
                
                
            }
           
        }
        .table{
            width: 100%;
            max-height: 400px;
            overflow: auto;            
        }
        
    }
    .footer{

        padding: 10px 0px;
        display: flex;
        justify-content: end;
        .footerWrap
        {
            -webkit-user-select: none;
            user-select: none;
            .Pagination
            {
                display: flex;
                flex-direction: row;
                justify-content:center;
                align-items: center;
                .prev{
                    cursor: pointer;
                    
                    padding: 4px;
                    font-size: 1rem;
                    font-weight: bold;
                    opacity: 0.7;
                    margin-right: 5px;
                }
                .prev:hover , .last:hover {
                    opacity: 0.9;
                }
                .item{
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white ;
                    width: 30px;
                    height: 30px;
                    border-radius: 10px;
                    opacity: 0.7;
                    font-size: 1rem;
                    font-weight: bold;
                    color: #424242;
                    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
                    transition: all 0.5s;
                    cursor: pointer;
                }
                .item.active{
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    background-color: var(--adminMainColor);
                    color: white;
                }
                .item:hover{
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
                    background-color: var(--adminMainColor);
                    color: white;
                }
                .last{
                    padding: 10px;
                    font-size: 1.2rem;
                    font-weight: bold;
                    opacity: 0.7;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}